<template>
            <div class="curren-elem">
            <div class="curr-i-wrap">
              <img src="/icons/currency-eur.svg" alt="" />
            </div>

            <span class="curr-flex">{{currency.eur.toFixed(2)}} <span class="red-symbol">₴</span></span>
          </div>
          <div class="curren-elem">
            <div class="curr-i-wrap">
              <img src="/icons/currency-usd.svg" alt="" />
            </div>

            <span class="curr-flex">{{currency.usd.toFixed(2)}} <span class="red-symbol">₴</span></span>
          </div>
          <div class="curren-elem">
            <div class="curr-i-wrap">
              <img src="/icons/cross.svg" alt="" />
            </div>

            <span>{{currency.cross.toFixed(3)}}</span>
          </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      currency: (state) => state.currency.currency,
    }),
  },
};
</script>

<style scoped>
.red-symbol{
  color: #f13333 !important;
  padding-left: 5px;
}
.curr-flex {
  display: flex !important;
}
.curren-elem{
  margin-bottom: 10px;
}
</style>