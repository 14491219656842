import { createStore } from 'vuex'
import { Popups } from '@/store/Popups'
import { Rollet } from '@/store/Rollet'
import { InsulatedRollet } from '@/store/InsulatedRollet'
import { Gate } from '@/store/Gate'
import { SlidingGate } from '@/store/SlidingGate'
import { SwingGate } from '@/store/SwingGate'
import { Door } from '@/store/Door'
import { Locale } from '@/store/Locale'
import { Currency } from '@/store/Currency'
import axios from 'axios'


export default createStore({
    state: () => ({
        langActive: 'ua',
        langs: {
            'ua': {
                'title': 'Українська',
            },
            'ru': {
                'title': 'Русский',
            },
        },
        products: {},
        alerts: [],
        searchQuery: '',
        category: 'all',
        page: 0,
        order: [],
        orderShare: [],
        orderComment: '',
        orderID: 0,
        orderDiscount: 0,
        currencyConvert: false,
        hideCards: false,
        mobileMenuShow: false,
        deferredPrompt: null

    }),
    mutations: {
        defaultOrder(state) {
            state.order = [];
            state.orderComment = '';
            state.orderID = 0;
            state.orderDiscount = 0;
        },
        setProducts(state, price) {
            state.products = price;
        },
        deferredPrompt(state, e) {
            state.deferredPrompt = e;
        },
        setAlerts(state, alerts) {
            state.alerts = alerts;
        },
        setOrderID(state, id) {
            state.orderID = id;
            localStorage.setItem("orderID", state.orderID);
        },
        setDiscount(state, discount) {
            state.orderDiscount = discount;
            localStorage.setItem("orderDiscount", JSON.stringify(state.orderDiscount));
        },
        setComment(state, comment) {
            state.orderComment = comment;
            localStorage.setItem("orderComment", JSON.stringify(state.orderComment));
        },
        updateCountOrder(state, data) {
            if (data.method == 'inc') {
                state.order[data.index].count += 1;
            }
            if (data.method == 'dec') {
                state.order[data.index].count -= 1;
            }
            if (state.order[data.index].count == 0 || data.method == 'del') {
                state.order.splice(data.index, 1);
                for (let i = 0; i < state.products.products.length; i++) {
                    if (state.products.products[i].id == data.id) {
                        state.products.products[i]['inOrder'] = false;
                        break
                    }
                }
            }
            localStorage.setItem("order", JSON.stringify(state.order));
        },
        updatePositionDiscount(state, data) {
            state.order[data.index].discount = Number(data.discount) >= 0 ? Number(data.discount) : 0;
            localStorage.setItem("order", JSON.stringify(state.order));
        },
        setCheckedConvert(state, val) {
            state.currencyConvert = val;
        },
        setHideCards(state, val) {
            state.hideCards = val;
        },
        toggleLang(state, lang) {
            state.langActive = lang;
        },
        toggleMobileMenu(state) {
            state.mobileMenuShow = !state.mobileMenuShow;
        },
        setSearchQuery(state, query) {
            state.searchQuery = query;
        },
        setFilterCategory(state, category) {
            state.category = category;
            state.page = 0;
        },
        incrementPage(state) {
            state.page += 1;
        },
        decrementPage(state) {
            state.page -= 1;
        },
        addToOrder(state, data) {
            if (data.type == 'product') {
                for (let i = 0; i < state.order.length; i++) {
                    if (state.order[i].type == 'product' && state.order[i].id == data.id) {
                        state.order[i].count += 1;
                        return
                    }
                }
                for (let i = 0; i < state.products.products.length; i++) {
                    if (state.products.products[i].id == data.id) {
                        state.products.products[i]['inOrder'] = true;
                        break
                    }
                }
                state.order.push({
                    id: data.id,
                    count: 1,
                    type: 'product',
                    price: data.price,
                    discount: data.discount,
                    title: data.title
                });
            }
            if (data.edit && data.type != 'product') {

                for (let i = 0; i < state.order.length; i++) {
                    // console.log(data.id == state.order[i].id);
                    // console.log(data.id + ' ' + state.order[i].id);
                    if (state.order[i].id == data.id) {
                        state.order[i].position = data.position;
                        state.order[i].price = data.price;
                        state.order[i].edit = false;
                        // console.log(state.order[i]);
                        break
                    }
                }
            } else {
                if (data.type == 'rollet') {
                    state.order.push({
                        position: data.position,
                        count: 1,
                        type: 'rollet',
                        price: data.price,
                        discount: data.discount,
                        id: data.id
                    });
                }
                if (data.type == 'insulated_rollet') {
                    state.order.push({
                        position: data.position,
                        count: 1,
                        type: 'insulated_rollet',
                        price: data.price,
                        discount: data.discount,
                        id: data.id
                    });
                }
                if (data.type == 'gate') {
                    state.order.push({
                        position: data.position,
                        count: 1,
                        type: 'gate',
                        discount: data.discount,
                        price: data.price,
                        id: data.id
                    });
                }
                if (data.type == 'sliding_gate') {
                    state.order.push({
                        position: data.position,
                        count: 1,
                        type: 'sliding_gate',
                        discount: data.discount,
                        price: data.price,
                        id: data.id
                    });
                }
                if (data.type == 'swing_gate') {
                    state.order.push({
                        position: data.position,
                        count: 1,
                        type: 'swing_gate',
                        discount: data.discount,
                        price: data.price,
                        id: data.id
                    });
                }
                if (data.type == 'door') {
                    state.order.push({
                        position: data.position,
                        count: 1,
                        type: 'door',
                        discount: data.discount,
                        price: data.price,
                        id: data.id
                    });
                }
            }

            localStorage.setItem("order", JSON.stringify(state.order));
            localStorage.setItem("orderDiscount", JSON.stringify(state.orderDiscount));
            localStorage.setItem("orderComment", JSON.stringify(state.orderComment));
        },
        setOrderLoad(state, data) {
            state.order = data.order;
            state.orderDiscount = data.orderDiscount;
            state.orderComment = data.orderComment;
            state.orderID = data.orderID;

        },
    },
    getters: {
        searchProduct: state => {
            try {
                return state.products.products.filter(product => product.title[state.langActive].toLowerCase().includes(state.searchQuery.toLowerCase()));
            } catch (error) {
                return '';
            }

        },
        getAlerts: state => {
            return state.products.news;
        },
        discountPercent: state => {
            return 1 - state.orderDiscount / 100;
        },
        filterCategory: (state, getters) => {
            if (state.category == 'all') {
                return getters.searchProduct;
            }
            return getters.searchProduct.filter(product => product.category_id == Number(state.category));
        },
        pageCount: (state, getters) => {
            try {
                return Math.ceil(getters.filterCategory.length / 15);
            } catch (error) {
                return 0;
            }

        },
        paginateProducts: (state, getters) => {
            if (getters.filterCategory.length <= 15) {
                return getters.filterCategory;
            }
            const start = state.page * 15,
                end = start + 15;
            return getters.filterCategory.slice(start, end);
        },
        orderSumm: (state) => {
            let summ = 0;
            let discount = 1 - state.orderDiscount / 100;
            if (state.currencyConvert) {
                for (const [key, element] of Object.entries(state.order)) {
                    let position_discount = element.discount / 100;
                    if (element.type != 'product') {
                        summ += element.count * (element.price.ua.sum * (discount - position_discount) + (element.price.ua.sumWithBox - element.price.ua.sum));
                    } else {
                        summ += element.count * element.price.ua * (discount - position_discount);
                    }
                }
                return summ.toFixed(2);
            } else {
                summ = 0;
                for (const [key, element] of Object.entries(state.order)) {
                    let position_discount = element.discount / 100;
                    if (element.type != 'product') {
                        summ += element.count * (element.price.eur.sum * (discount - position_discount) + (element.price.eur.sumWithBox - element.price.eur.sum));
                    } else {
                        summ += element.count * element.price.eur * (discount - position_discount);
                    }
                }
                return summ.toFixed(2);
            }


        },
        orderCount: (state) => {
            return Object.entries(state.order).length;
        },
        getCurrencyIcon: (state) => {
            if (state.currencyConvert) {
                return '₴';
            } else {
                return '€';
            }
        }
    },
    actions: {
        loadProducts({ commit }) {
            axios.get('https://calculator.gant.ua/api/json.php').then((response) => {
                commit('setProducts', response.data);
            })
        },
        loadAlerts({ commit }) {
            axios.get('https://calculator.gant.ua/api/alerts.php').then((response) => {
                commit('setAlerts', response.data);
            })
        },
        loadOrder({ commit }) {
            let order = JSON.parse(localStorage.getItem("order"));
            let orderDiscount = JSON.parse(localStorage.getItem("orderDiscount"));
            let orderComment = JSON.parse(localStorage.getItem("orderComment"));
            let orderID = Number(localStorage.getItem("orderID"));
            if (orderID == null) {
                orderID = 0;
            }
            if (order != undefined) {
                commit("setOrderLoad", { order: order, orderDiscount: orderDiscount, orderComment: orderComment, orderID: orderID });
            }

        },
        categoryProductCount({ state }, category) {
            let count = state.products.products.filter(product => product.category_id == Number(category)).length;
            if (count > 0) {
                return true;
            } else {
                return false;
            }
        },
        getProductByID({ state }, id) {
            let product = state.products.products.filter(product => product.id == Number(id))[0];
            return product;
        }
    },
    modules: {
        popups: Popups,
        rollet: Rollet,
        insulated_rollet: InsulatedRollet,
        gate: Gate,
        sliding_gate: SlidingGate,
        swing_gate: SwingGate,
        door: Door,
        locale: Locale,
        currency: Currency,
    }
})