import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import HeaderVue from "@/components/HeaderVue";
import SettingsPopup from "@/components/popups/SettingsPopup";
import axios from "axios";
import VueAxios from "vue-axios";
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";
import VueSocialSharing from "vue-social-sharing";
import VueGtag from "vue-gtag";
import "./registerServiceWorker";

let app = createApp(App)
  .use(store)
  .use(router)
  .use(Vue3SimpleHtml2pdf)
  .use(VueSocialSharing)
  // .use(Vuex)
  .use(VueAxios, axios)
  .component("HeaderVue", HeaderVue)
  .component("SettingsPopup", SettingsPopup)
  .use(VueGtag, {
    config: { id: "G-S0VE3LFY75" },
    router,
  })
  .mount("#app");
