<template>
  <div class="popup" id="single-product" v-if="getProduct.length > 0">
    <div class="popup-wrap popup-wrap-flex">
      <img
        class="close_all singl-x cursor-point"
        src="/icons/cloz.svg"
        @click="$store.commit('popups/toggleProductDetails')"
      />
      <div class="col">
        <div class="prod-card prod-card-popup">
          <div class="prod-card-main">
            <swiper :slides-per-view="1" navigation :loop="true">
              <swiper-slide
                class="prod-img-wrap img-popup"
                v-for="(img, key) in getProduct[0].photos"
                :key="key"
              >
                <img :src="img" alt="" />
              </swiper-slide>
            </swiper>
            <span class="prod-name prod-name-popup">{{
              getProduct[0].title[$store.state.langActive]
            }}</span>
            <span class="price"
              >{{ convert(getProduct[0].price) }}
              {{ $store.getters.getCurrencyIcon }}</span
            >
            <a
              :href="getTranslate('viewPartnersURL', $store.state.langActive)"
              target="_blank"
              ><span class="btn-clear">{{
                getTranslate("viewPartnersLabel", $store.state.langActive)
              }}</span></a
            >
          </div>
          <button
            class="btn"
            :class="{
              btn__incart: inOrder(getProduct[0].id),
            }"
            @click="
              addToOrder({
                id: getProduct[0].id,
                title: getProduct[0].title[$store.state.langActive],
                discount: 0,
                price: {
                  eur: getProduct[0].price,
                  ua: (
                    getProduct[0].price * $store.state.currency.currency.eur
                  ).toFixed(2),
                },
                type: 'product',
              })
            "
          >
            <template v-if="!inOrder(getProduct[0].id)">{{
              getTranslate("addToOrder", $store.state.langActive)
            }}</template>
            <template v-if="inOrder(getProduct[0].id)">{{
              getTranslate("inOrder", $store.state.langActive)
            }}</template>
          </button>
        </div>
      </div>
      <div class="col">
        <div class="tabs">
          <nav class="tab__items">
            <a
              @click.prevent="tabIndex = true"
              class="tab__item"
              :class="{ 'tab-active': tabIndex }"
              ><span>{{
                getTranslate("productDescription", $store.state.langActive)
              }}</span></a
            >
            <a
              @click.prevent="tabIndex = false"
              class="tab__item"
              :class="{ 'tab-active': !tabIndex }"
              ><span>ХАРАКТЕРИСТИКИ</span></a
            >
          </nav>
          <div class="tabs__body">
            <div
              class="tab__block description"
              v-html="getProduct[0].descriptions[$store.state.langActive]"
              v-if="tabIndex"
            ></div>
            <div class="tab__block" v-else>
              <div class="popup-table">
                <div
                  class="popup-table-row"
                  v-for="(spec, key) in getProduct[0].specifications"
                  :key="key"
                >
                  <div class="popup-table-cell cell-1">
                    {{ spec.name[$store.state.langActive] }}
                  </div>
                  <div class="popup-table-cell cell-2">
                    {{ spec.value[$store.state.langActive] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";
SwiperCore.use([Navigation, Pagination, A11y]);
export default {
  data: function () {
    return {
      tabIndex: true,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapState({
      product_id: (state) => state.popups.activeProduct,
    }),
    ...mapGetters("popups", ["getProduct"]),
    ...mapGetters("locale", ["getTranslate"]),
  },
  methods: {
    convert(price) {
      if (this.$store.state.currencyConvert) {
        return (price * this.$store.state.currency.currency.eur).toFixed(2);
      } else {
        return price.toFixed(2);
      }
    },
    addToOrder(data) {
      this.$store.commit("addToOrder", data);
    },
    inOrder(id) {
      try {
        let products = this.$store.state.order.filter(
          (product) => product.id == id
        );
        if (products.length > 0) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.tab__item {
  cursor: pointer;
}
.tab__block {
  display: block;
}
</style>
<style>
.img-popup {
  height: 250px !important;
}
.prod-name-popup {
  height: auto !important;
}
.prod-card-popup {
  width: 100%;
}
.prod-card-popup .prod-card-main {
  padding-left: 10px;
  padding-right: 10px;
}
.prod-card-popup .prod-card-main .prod-img-wrap {
  max-width: 100%;
}
.tab__block.description,
.tab__block.description li {
  font-weight: normal;
}
.tab__block.description p {
  text-indent: 15px;
  text-align: justify;
}
.popup-table-cell.cell-2 {
  font-weight: 400;
}
</style>
