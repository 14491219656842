import { createRouter, createWebHistory } from 'vue-router'
import Rollet from '@/views/Rollet.vue'
import Gate from '@/views/Gate.vue'
import SlidingGate from '@/views/SlidingGate.vue'
import SwingGate from '@/views/SwingGate.vue'
import Door from '@/views/Door.vue'
import InsulatedRollet from '@/views/InsulatedRollet.vue'
import Shop from '@/views/Shop.vue'
import Order from '@/views/Order.vue'
import OrderShare from '@/views/OrderShare.vue'
import Alerts from '@/views/Alerts.vue'

const routes = [{
        path: '/',
        name: 'Shop',
        component: Shop
    },
    {
        path: '/gate',
        name: 'Gate',
        component: Gate
    },
    {
        path: '/sliding-gate',
        name: 'SlidingGate',
        component: SlidingGate
    },
    {
        path: '/swing-gate',
        name: 'SwingGate',
        component: SwingGate
    },
    {
        path: '/door',
        name: 'Door',
        component: Door
    },
    {
        path: '/rollet',
        name: 'Rollet',
        component: Rollet
    },
    {
        path: '/insulated-rollet',
        name: 'InsulatedRollet',
        component: InsulatedRollet
    },
    {
        path: '/order',
        name: 'Order',
        component: Order
    },
    {
        path: '/order/:id',
        name: 'OrderShare',
        component: OrderShare
    },
    {
        path: '/alerts',
        name: 'Alerts',
        component: Alerts
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: "active",
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
})

export default router