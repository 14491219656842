<template>
  <div class="main-wrap main-wrap-order">
    <span class="h2 h2-1">
      {{ getTranslate("alerts_title", $store.state.langActive) }}        
      </span>
    <div class="alerts-wrapp">
      <div class="alert-card" v-for="(alert, index) in getAlerts" :key="index">
        <h3>{{alert.title[$store.state.langActive]}}</h3>
        <p class="time">{{alert.time}}</p>
        <div class="alert-content" v-html="alert.content[$store.state.langActive]"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import QrcodeVue from "qrcode.vue";

import axios from "axios";
export default {
  data() {
    return {
      
    };
  },
  computed: {
    ...mapGetters("locale", ["getTranslate"]),
    ...mapGetters(["orderSumm", "discountPercent", "getAlerts"]),
    ...mapState({
      priceGate: (state) => state.gate.price,
      priceRollet: (state) => state.rollet.price,
      orderDiscount: (state) => state.orderDiscount,
      orderComment: (state) => state.orderComment,
    }),
  },
};
</script>

<style scoped>
.alert-card{
  padding-bottom: 10px;
  border-bottom: 2px solid #f13333;
}
</style>
