<template>
  <div class="popup popup-curr" id="p-currency">
    <div class="popup-wrap">
      <span class="h3">Курс на <span class="h3 red">{{date}}</span> </span>
      <div class="curr-block">
        <div class="currency-wrap">
          <currency></currency>
        </div>
      </div>
      <img
        class="close_all curr-x cursor-point"
        id="curr-x"
        src="/icons/cloz.svg"
        @click="$store.commit('popups/toggleCurrency')"
      />
    </div>
  </div>
</template>

<script>
import Currency from "@/components/Currency";
import { mapState } from "vuex";
export default {
  components: {
    Currency,
  },
  computed: {
    ...mapState({
      date: (state) => state.currency.date,
    }),
  },
};
</script>

<style scoped>
.popup-wrap{
    width: max-content;
    padding-right: 91px;
}
</style>