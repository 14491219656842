<template>
  <div class="accomp-prod-wrap" v-if="getProducts.length > 0">
    <span class="h2">{{
      getTranslate("relatedProducts", $store.state.langActive)
    }}</span>
    <swiper
      class="slider-wrapp-related"
      :slides-per-view="4"
      :space-between="15"
      :breakpoints="swiperBreakpoints"
      navigation
      :loop="true"
    >
      <swiper-slide
        class="prod-card products-item"
        v-for="(product, key) in getProducts"
        :key="key"
      >
        <div class="prod-card-main">
          <a
            href=""
            class="prod-info"
            @click.prevent="
              $store.commit('popups/setActiveProduct', product.id)
            "
            ><img src="/icons/info.svg" alt=""
          /></a>
          <a
            :href="getTranslate('viewPartnersURL', $store.state.langActive)"
            target="_blank"
            class="prod-view"
            ><img src="/icons/view.svg" alt=""
          /></a>
          <div class="prod-img-wrap">
            <img
              :src="product.photos[0]"
              alt=""
              v-if="product.photos[0] != undefined"
            />
            <img src="/img/no-image.png" alt="" v-else />
          </div>
          <span class="prod-name" :title="product.title[$store.state.langActive]">{{
            product.title[$store.state.langActive]
          }}</span>
          <span class="price"
            >{{ convert(product.price) }}
            {{ $store.getters.getCurrencyIcon }}</span
          >
        </div>
        <button
          class="btn"
          :class="{
            btn__incart: inOrder(product.id),
          }"
          @click="
            addToOrder({
              id: product.id,
              title: product.title[$store.state.langActive],
              discount: 0,
              price: {
                eur: product.price,
                ua: (
                  product.price * $store.state.currency.currency.eur
                ).toFixed(2),
              },
              type: 'product',
            })
          "
        >
          <template v-if="!inOrder(product.id)">{{
            getTranslate("addToOrder", $store.state.langActive)
          }}</template>
          <template v-if="inOrder(product.id)">{{
            getTranslate("inOrder", $store.state.langActive)
          }}</template>
        </button>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";
SwiperCore.use([Navigation, Pagination, A11y]);
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: ["type"],
  data() {
    return {
      swiperBreakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        400: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        700: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 15,
        },
      },
    };
  },
  computed: {
    ...mapGetters("locale", ["getTranslate"]),
    ...mapState({
      cardsState: (state) => state.rollet.cardsState,
      position: (state) => state.rollet.position,
      price: (state) => state.rollet.price,
    }),
    ...mapGetters("rollet", { rolletRelated: "relatedProducts" }),
    ...mapGetters("gate", { gateRelated: "relatedProducts" }),
    ...mapGetters("sliding_gate", { slidingGateRelated: "relatedProducts" }),
    ...mapGetters("swing_gate", { swingGateRelated: "relatedProducts" }),
    ...mapGetters("door", { doorRelated: "relatedProducts" }),
    getProducts: function () {
      if (this.type == "rollet") {
        return this.rolletRelated;
      }
      if (this.type == "gate") {
        return this.gateRelated;
      }
      if (this.type == "door") {
        return this.doorRelated;
      }
      if (this.type == "sliding_gate") {
        return this.slidingGateRelated;
      }
      if (this.type == "swing_gate") {
        return this.swingGateRelated;
      }
    },
  },
  methods: {
    addToOrder(data) {
      this.$store.commit("addToOrder", data);
    },

    convert(price) {
      if (this.$store.state.currencyConvert) {
        return (price * this.$store.state.currency.currency.eur).toFixed(2);
      } else {
        return price.toFixed(2);
      }
    },

    inOrder(id) {
      try {
        let products = this.$store.state.order.filter(
          (product) => product.id == id
        );
        if (products.length > 0) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.slider-wrapp-related {
  margin-top: 20px;
}
.prod-card .prod-card-main .prod-name {
  font-size: 14px;
  height: 50px;
}
.prod-card .prod-card-main .price {
  font-size: 24px;
}
.btn {
  cursor: pointer;
}
</style>
<style>
.main-wrap,
.main-content {
  min-width: 0 !important;
}
.swiper-button-next,
.swiper-button-prev {
  color: #f13333;
}
.prod-card .prod-card-main .prod-name {
  height: 54px;
  overflow-y: hidden;
}
</style>