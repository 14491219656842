export const Locale = {
    state: () => ({
        addToOrder: {
            ru: 'ДОБАВИТЬ В ЗАКАЗ',
            ua: 'ДОДАТИ ДО ЗАМОВЛЕННЯ'
        },
        positionDiscount: {
            ru: 'Скидка на позицию, %',
            ua: 'Знижка на позицію, %'
        },
        gateCalcTitlePage: {
            ru: 'Расчет стоимости ворот',
            ua: 'Розрахунок вартості воріт',
        },
        slidingGateCalcTitlePage: {
            ru: 'Расчет стоимости откатных ворот',
            ua: 'Розрахунок вартості відкатних воріт',
        },
        swingGateCalcTitlePage: {
            ru: 'Расчет стоимости распашных ворот',
            ua: 'Розрахунок вартості розпашних воріт',
        },
        rolletCalcTitlePage: {
            ru: 'Расчет стоимости роллет',
            ua: 'Розрахунок вартості ролет',
        },
        insulatedRolletCalcTitlePage: {
            ru: 'Расчет стоимости роллет с Термо коробом',
            ua: 'Розрахунок вартості ролет з Термо коробом',
        },
        doorCalcTitlePage: {
            ru: 'Расчет стоимости боковых дверей GT',
            ua: 'Розрахунок вартості бічних дверей GT',
        },
        sizes: {
            ru: 'Размеры',
            ua: 'Розміри',
        },
        color: {
            ru: 'Цвет',
            ua: 'Колір',
        },
        rollet: {
            ru: 'Ролеты',
            ua: 'Ролети',
        },
        insulatedRollet: {
            ru: 'Ролеты с Термо коробом',
            ua: 'Ролети з Термо коробом',
        },
        name: {
            ru: 'Название',
            ua: 'Назва',
        },
        gateType: {
            ru: 'Тип ворот',
            ua: 'Тип воріт',
        },
        gateAccessories: {
            ru: 'Дополнительные опции и аксессуары',
            ua: 'Додаткові опції і аксесуари',
        },
        relatedProducts: {
            ru: 'Сопутствующие товары',
            ua: 'Супутні товари'
        },
        discount: {
            ru: 'Скидка',
            ua: 'Знижка'
        },
        positionName: {
            ru: 'Название для сохранения в корзине',
            ua: 'Назва для збереження у кошику',
        },
        clearForm: {
            ru: 'ОЧИСТИТЬ ФОРМУ',
            ua: 'ОЧИСТИТИ ФОРМУ',
        },
        details: {
            ru: 'Детальнее...',
            ua: 'Детальніше...',
        },
        alerts_title: {
            ru: 'Уведомления',
            ua: 'Повідомлення',
        },
        updateOrder: {
            ru: 'ОБНОВИТЬ',
            ua: 'ОНОВИТИ',
        },
        inOrder: {
            ru: 'ДОБАВЛЕНО',
            ua: 'ДОДАНО',
        },
        positionPrice: {
            ru: 'ЦЕНА КОМПЛЕКТА',
            ua: 'ЦІНА КОМПЛЕКТУ',
        },
        positionWidth: {
            ru: 'Ширина проема (мм)',
            ua: 'Ширина проєму (мм)',
        },
        positionHeight: {
            ru: 'Высота проема (мм)',
            ua: 'Висота проєму (мм)'
        },
        windowWidth: {
            ru: 'Ширина окна (мм)',
            ua: 'Ширина вікна (мм)',
        },
        windowHeight: {
            ru: 'Высота окна (мм)',
            ua: 'Висота вікна (мм)'
        },
        complection: {
            ru: 'Базовая комплектация',
            ua: 'Базова комплектація',
        },
        profileType: {
            ru: 'ТИП ПРОФИЛЯ',
            ua: 'ТИП ПРОФІЛЮ'
        },
        remoteType: {
            ru: 'ПУЛЬТЫ ДИСТАНЦИОННОГО УПРАВЛЕНИЯ',
            ua: 'ПУЛЬТИ ДИСТАНЦІЙНОГО КЕРУВАННЯ'
        },
        mountingType: {
            ru: 'ВИД МОНТАЖА',
            ua: 'ВИД МОНТАЖУ'
        },
        control: {
            ru: 'УПРАВЛЕНИЕ',
            ua: 'УПРАВЛІННЯ'
        },
        checkControlType: {
            ru: 'Выберите тип управления',
            ua: 'Оберіть тип управління'
        },
        profileTypeOrder: {
            ru: 'Тип профиля',
            ua: 'Тип профілю',
        },
        mountingTypeOrder: {
            ru: 'Тип монтажа',
            ua: 'Тип монтажу'
        },
        settings: {
            ru: 'Настройки',
            ua: 'Налаштування',
        },
        orderList: {
            ru: 'Состав заказа',
            ua: 'Склад замовлення',
        },
        gateCalc: {
            ru: 'Ворота',
            ua: 'Ворота',
        },
        slidingGateCalc: {
            ru: 'Откатные ворота',
            ua: 'Відкатні ворота',
        },
        swingGateCalc: {
            ru: 'Распашные ворота',
            ua: 'Розпашні ворота',
        },
        rolletCalc: {
            ru: 'Роллеты',
            ua: 'Ролети',
        },
        doorCalc: {
            ru: 'Двери',
            ua: 'Двері',
        },
        insulatedRolletCalc: {
            ru: 'Роллеты с Термо коробом',
            ua: 'Ролети з Термо коробом',
        },
        products: {
            ru: 'Товары',
            ua: 'Товари',
        },
        orderCount: {
            ru: 'Товаров',
            ua: 'Товарів',
        },
        orderSum: {
            ru: 'Сумма',
            ua: 'Сума',
        },
        downloadApp: {
            ru: 'Скачать приложение',
            ua: 'Завантажити додаток'
        },
        categories: {
            ru: 'Категории',
            ua: 'Категорії',
        },
        allProducts: {
            ru: 'Все товары',
            ua: 'Усі товари'
        },
        productsNotFound: {
            ru: 'Нет товаров с заданными параметрами',
            ua: 'Товарів з даними параметрами не знайдено',
        },
        search: {
            ru: ' Поиск',
            ua: ' Пошук',
        },
        page: {
            ru: 'Страница',
            ua: 'Сторінка',
        },
        from: {
            ru: 'из',
            ua: 'з',
        },
        orderPosition: {
            ru: 'Позиция заказа',
            ua: 'Позиція замовлення',
        },
        count: {
            ru: 'Количество',
            ua: 'Кількість',
        },
        price: {
            ru: 'Цена',
            ua: 'Ціна',
        },
        priceDiscount: {
            ru: 'Сумма со скидкой',
            ua: 'Сума зі знижкою',
        },
        orderPrice: {
            ru: 'Итого со скидкой',
            ua: 'Всього зі знижкою',
        },
        orderNotes: {
            ru: 'Примечания к заказу',
            ua: 'Примітки до замовлення',
        },
        orderPrint: {
            ru: 'Распечатать',
            ua: 'Роздрукувати',
        },
        orderSave: {
            ru: 'Сохранить',
            ua: 'Зберегти',
        },
        cartEmpty: {
            ru: 'Добавьте позиции в заказ',
            ua: 'Додайте позиції до замовлення',
        },
        orderNotFound: {
            ru: 'Заказ не найден',
            ua: 'Замовлення не знайдено',
        },
        dateCreate: {
            ru: 'Дата создания',
            ua: 'Дата створення',
        },
        orderPreview: {
            ru: 'Просмотр документа',
            ua: 'Перегляд документа',
        },
        viewPartnersURL: {
            ru: "https://gant.ua/contacts/?tab=where-buy#content",
            ua: "https://gant.ua/ua/contacts/?tab=where-buy#content",
        },
        viewPartnersLabel: {
            ru: "ГДЕ КУПИТЬ",
            ua: "ДЕ КУПИТИ",
        },
        productDescription: {
            ru: "ОПИСАНИЕ",
            ua: "ОПИС",
        },
        deltaProfile: {
            ru: "Рекомендуется установка усиливающего дельта-профиля во избежание прогиба панелей",
            ua: "Рекомендується встановлення підсилювального дельта-профілю, щоб уникнути прогину панелей",
        },
        lintelLabel: {
            ru: "Притолока (h), мм",
            ua: "Притолока (h), мм",
        },
        gateStandartColors: {
            ru: "Стандартные цвета",
            ua: "Стандартні кольори",
        },
        gateCustomColors: {
            ru: "Принтинг",
            ua: "Принтинг",
        },
        gateLiftType: {
            ru: "Тип подъема (для информации)",
            ua: "Тип підйому (для інформації)",
        },
        settingCompact: {
            ru: "компактный вид калькулятора<br/>(карточки разделов свернуты)",
            ua: "компактний вигляд калькулятору<br/>(картки разділів згорнуті)",
        },
        settingCurrency: {
            ru: "цены в UAH ",
            ua: "ціни в UAH ",
        },
        appInstall: {
            ru: "Установить на устройство",
            ua: "Встановити на пристрій",
        },
        gateAlert: {
            ru: "Данные размеры производятся Исключительно после согласования с вашим менеджером.",
            ua: "Дані розміри виготовляються Виключно після узгодження з Вашим менеджером",
        },
        stockAlert: {
            ru: "Уточните наличие цвета панелей у менеджера.",
            ua: "Уточніть наявність кольору панелей у менеджера.",
        },
        sideDoor: {
            ru: "Двери",
            ua: "Двері",
        },
        orderSideDoor: {
            ru: "Боковые двери серии GT",
            ua: "Бічні двері серії GT",
        },
        toOrderDoorColor: {
            ru: "Под заказ, согласовывается с менеджером",
            ua: "Під замовлення, уточнюється із менеджером",
        }
    }),
    getters: {
        getTranslate: state => (key, lang) => {
            if (state[key] != undefined) {
                return state[key][lang];
            }
            return key;
        },
    },
    namespaced: true
}