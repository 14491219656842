import axios from 'axios'
import VueAxios from 'vue-axios'

export const Rollet = {
    state: () => ({
        cardsState: {
            size: false,
            profile: false,
            mounting: false,
            color: false,
            delivery: false,
            control: false,
            remote: false,
        },
        position: {
            width: 0,
            height: 0,
            profile: '',
            mounting: '',
            color: '',
            delivery: '',
            control: '',
            checkedControl: [],
            accInput: {},
            remotes: {},
            discount: 0,
            outsetText: {
                height: 0,
                width: 0
            },
            name: ''
        },
        edit: false,
        editID: 0,
        inOrder: false,
        view: false,
        price: {}

    }),
    mutations: {
        toggleCard(state, key) {
            state.cardsState[key] = !state.cardsState[key];
        },
        viewPosition(state, data) {
            state.position = data.position;
            state.editID = data.id;
            state.view = true;
        },
        toggleIsView(state) {
            state.view = false;
        },
        updatePrice(state, price) {
            state.price = price;
        },
        toggleIsEdit(state, id) {
            state.edit = false;
            state.editID = 0;
        },
        toggleInOrder(state) {
            state.inOrder = true;
        },
        editPosition(state, data) {
            state.position = data.position;
            state.editID = data.id;
            state.edit = true;
            state.inOrder = false
        },
        setHideCards(state, val) {
            state.cardsState = {
                size: val,
                profile: val,
                mounting: val,
                color: val,
                delivery: val,
                control: val,
                remote: val,
            };
        },
        setPosition(state, position) {
            state.position = position;
        },
        remoteDec(state, key) {
            if (state.position.remotes[key]['count'] > 0) {
                state.position.remotes[key]['count'] -= 1;
            }

        },
        remoteInc(state, key) {
            state.position.remotes[key]['count'] += 1;
        },
        setPositionValue(state, data) {
            if (isNaN(data.value)) {
                state.position[data.key] = data.value;
            } else {
                state.position[data.key] = Number(data.value);
            }

        },
        setDefaultValue(state, data) {
            let inputAcc = {};
            try {
                inputAcc = state.position.accInput;
                let inputAccKey = Object.keys(state.position.accInput);
                for (let i = 0; i < inputAccKey.length; i++) {
                    inputAcc[inputAccKey[i]] = 0;
                }
            } catch (error) {
                inputAcc = {};
            }
            let remotes = {};
            try {
                let remotes = state.price.remote;
                let remotesKey = Object.keys(remotes);
                for (let i = 0; i < remotesKey.length; i++) {
                    remotes[remotesKey[i]]['count'] = 0;
                }
            } catch (error) {
                console.log(error);
                remotes = {};
            }
            let position = localStorage.getItem('rollet');
            if (position != undefined) {
                state.position = JSON.parse(position);
            } else {
                state.position = {
                    width: 0,
                    height: 0,
                    profile: Object.keys(data.profile)[0],
                    mounting: Object.keys(data.mounting)[0],
                    color: Object.keys(data.colors)[0],
                    delivery: Object.keys(data.delivery)[0],
                    control: Object.keys(data.control)[0],
                    checkedControl: [],
                    accInput: inputAcc,
                    remotes: remotes,
                    discount: 0,
                    outsetText: {
                        height: 0,
                        width: 0
                    },
                    name: ''
                };
            }

        },
        clearForm(state) {
            let inputAcc = {};
            try {
                inputAcc = state.position.accInput;
                let inputAccKey = Object.keys(state.position.accInput);
                for (let i = 0; i < inputAccKey.length; i++) {
                    inputAcc[inputAccKey[i]] = 0;
                }
            } catch (error) {
                inputAcc = {};
            }
            let remotes = {};
            try {
                remotes = state.price.remote;
                let remotesKey = Object.keys(remotes);
                for (let i = 0; i < remotesKey.length; i++) {
                    remotes[remotesKey[i]]['count'] = 0;
                }
            } catch (error) {
                remotes = {};
            }
            state.position = {
                width: 0,
                height: 0,
                profile: Object.keys(state.price.profile)[0],
                mounting: Object.keys(state.price.mounting)[0],
                color: Object.keys(state.price.colors)[0],
                delivery: Object.keys(state.price.delivery)[0],
                control: Object.keys(state.price.control)[0],
                checkedControl: [],
                accInput: inputAcc,
                remotes: remotes,
                discount: 0,
                outsetText: {
                    height: 0,
                    width: 0
                },
                name: ''
            };
            state.inOrder = false;
            localStorage.removeItem('rollet');
        },
        setOffset(state, data) {
            state.position.outsetText[data.key] = data.value;
        },
        setCheckedElements(state, value) {
            state.position.checkedControl = value;
        },
        setAccInput(state, data) {
            state.position.accInput[data.key] = Number(data.value);
        }
    },
    getters: {
        colorImage: state => {
            try {
                return state.price.colors[state.position.color].image;
            } catch (error) {
                return '';
            }

        },
        getID: state => {
            if (state.edit) {
                return state.editID;
            } else {
                return Date.now();
            }
        },
        deliveryImage: state => {
            try {
                return state.price.delivery[state.position.delivery].image;
            } catch (error) {
                return '';
            }

        },
        priceMessage: state => {
            try {
                let position = state.position;
                let price = state.price;
                if (price.price[position.profile][position.control][position.color].price == false) {
                    return true;
                }
            } catch (error) {
                return false;
            }

        },
        positionPrice: (state, getters, rootState) => {
            let position = {};
            let price = {};
            let width = 0;
            let height = 0;
            try {
                position = state.position;
                price = state.price.price[position.profile][position.control];
                width = position.width;
                height = position.height;
            } catch (error) {
                let sum = 0;
                return {
                    eur: {
                        sum: 0,
                        sumWithBox: 0
                    },
                    ua: {
                        sum: 0,
                        sumWithBox: 0
                    }
                }
            }
            localStorage.setItem('rollet', JSON.stringify(position));
            if (position.mounting == 'outset') {
                let widthOffset = 0;
                let heightOffset = 0;
                for (let i = 0; i < price.ofseth.length; i++) {
                    heightOffset = price.ofseth[i].s;
                    if (height <= price.ofseth[i].h) {
                        break;
                    }
                }
                for (let i = 0; i < price.ofsetw.length; i++) {
                    widthOffset = price.ofsetw[i].s;
                    if (width <= price.ofsetw[i].h) {
                        break;
                    }
                }
                height += heightOffset;
                width += widthOffset;

            }
            state.position.height_offset = height;
            state.position.width_offset = width;
            let widthItems = 0;
            let heightItems = 0;
            try {
                widthItems = price['width'];
                heightItems = price['height'];
            } catch (error) {
                let sum = 0;
                return {
                    eur: {
                        sum: 0,
                        sumWithBox: 0
                    },
                    ua: {
                        sum: 0,
                        sumWithBox: 0
                    }
                }
            }

            if (width >= price['width'][0] && height >= price['height'][0]) {
                if (height > heightItems[heightItems.length - 1] || width > widthItems[widthItems.length - 1]) {
                    let sum = 0;
                    return {
                        eur: {
                            sum: 0,
                            sumWithBox: 0
                        },
                        ua: {
                            sum: 0,
                            sumWithBox: 0
                        }
                    }
                }
                let widthIndex = 0;
                let heightIndex = 0;
                for (let i = 0; i < price.width.length; i++) {
                    if (width <= price.width[i]) {
                        widthIndex = i;
                        break;
                    }
                }
                for (let i = 0; i < price.height.length; i++) {
                    if (height <= price.height[i]) {
                        heightIndex = i;
                        break;
                    }
                }

                let sum = 0;
                if (price[position.color] == undefined) {
                    sum = price['size'][heightIndex][widthIndex];
                } else {
                    // return price[position.color].message[rootState.langActive];
                    if (price[position.color].price == false) {
                        return {
                            eur: {
                                sum: 0,
                                sumWithBox: 0
                            },
                            ua: {
                                sum: 0,
                                sumWithBox: 0
                            }
                        }
                        // return price[position.color].message[rootState.langActive];
                    } else {
                        sum = price[position.color][heightIndex][widthIndex];
                    }
                }
                if (state.price.control[position.control].accessories) {
                    let checked = state.position.checkedControl
                    for (let i = 0; i < checked.length; i++) {
                        sum += state.price.accessories[checked[i]].price;
                    }
                    let inputAcc = state.position.accInput;
                    let inputAccKey = Object.keys(state.position.accInput);
                    for (let i = 0; i < inputAccKey.length; i++) {
                        sum += state.price.accessories[inputAccKey[i]].price * inputAcc[inputAccKey[i]];
                    }
                }

                // state.position.remotes[key]['count'] -= 1;
                let remotes;
                try {
                    remotes = state.position.remotes;
                    let remotesKey = Object.keys(remotes);
                    for (let i = 0; i < remotesKey.length; i++) {
                        sum += remotes[remotesKey[i]]['count'] * remotes[remotesKey[i]]['price'];
                    }
                } catch (error) {
                    remotes = {};
                }

                sum = sum * (1 - position.discount / 100);
                let box = 0;
                // if (width <= 3000) {
                //     if (position.delivery == 'cardboard') {
                //         box = state.price.delivery[position.delivery].price * width / 1000;
                //     } else {
                //         box = state.price.delivery[position.delivery].price * width / 1000;
                //     }
                // } else {
                //     if (position.delivery == 'cardboard') {
                //         box = state.price.delivery[position.delivery].price_over * width / 1000;
                //     } else {
                //         box = state.price.delivery[position.delivery].price * width / 1000;
                //     }
                // }

                box = state.price.delivery[position.delivery].price * width / 1000;

                if(position.profile == 'SL100' || position.profile == 'SE45S') {
                    if(!state.price.profile[position.profile].exclude_deliveries.includes(position.delivery)) {
                        box = state.price.delivery[position.delivery].price * width / 1000 * 2;
                    } else {
                        box = 0;
                    }
                }

                let sumWithBox = sum + box;
                return {
                    eur: {
                        sum: sum,
                        sumWithBox: sumWithBox
                    },
                    ua: {
                        sum: sum * rootState.currency.currency.eur,
                        sumWithBox: sumWithBox * rootState.currency.currency.eur
                    }
                }

            } else {
                let sum = 0;
                return {
                    eur: {
                        sum: 0,
                        sumWithBox: 0
                    },
                    ua: {
                        sum: 0,
                        sumWithBox: 0
                    }
                }
            }
        },
        positionSize: (state, getters, rootState) => {
            let position = {};
            let price = {};
            let width = 0;
            let height = 0;
            try {
                position = state.position;
                price = state.price.price[position.profile][position.control];
                width = position.width;
                height = position.height;
            } catch (error) {
                let sum = 0;
                if (rootState.currencyConvert) {
                    return sum.toFixed(2);
                } else {
                    return sum.toFixed(2);
                }
            }
            if (position.mounting == 'outset') {
                let widthOffset = 0;
                let heightOffset = 0;
                for (let i = 0; i < price.ofseth.length; i++) {
                    heightOffset = price.ofseth[i].s;
                    if (height <= price.ofseth[i].h) {
                        break;
                    }
                }
                for (let i = 0; i < price.ofsetw.length; i++) {
                    widthOffset = price.ofsetw[i].s;
                    if (width <= price.ofsetw[i].h) {
                        break;
                    }
                }
                height += heightOffset;
                width += widthOffset;
                return width + " х " + height + " мм <span style='color: #ee3124'>(проем = " + widthOffset + "/" + heightOffset + "мм)</span>";
            }
            return width + " х " + height + " мм";
        },

        relatedProducts: (state, getters, rootState) => {
            try {
                return rootState.products.products.filter(product => product.category_id == Number(state.price.related_category_id));
            } catch (error) {
                return []
            }

        },
        showAlert: (state, getters, rootState) => {
            try {
                let width = state.position.width;
                let height = state.position.height;
                let position = state.position;
                let price = state.price.price[position.profile][position.control];
                let min_width = state.price.price[state.position.profile][state.position.control].width[0];
                let min_height = state.price.price[state.position.profile][state.position.control].height[0];
                let max_width = state.price.price[state.position.profile][state.position.control].width[state.price.price[state.position.profile][state.position.control].width.length - 1];
                let max_height = state.price.price[state.position.profile][state.position.control].height[state.price.price[state.position.profile][state.position.control].height.length - 1];
                if (state.position.mounting == 'outset') {
                    let widthOffset = 0;
                    let heightOffset = 0;
                    for (let i = 0; i < price.ofseth.length; i++) {
                        heightOffset = price.ofseth[i].s;
                        if (height <= price.ofseth[i].h) {
                            break;
                        }
                    }
                    for (let i = 0; i < price.ofsetw.length; i++) {
                        widthOffset = price.ofsetw[i].s;
                        if (width <= price.ofsetw[i].h) {
                            break;
                        }
                    }

                    height += heightOffset;
                    width += widthOffset;
                }
                if (height < 1000 || width < 100) {
                    return false;
                }
                let widthIndex = 0;
                let heightIndex = 0;
                for (let i = 0; i < price.width.length; i++) {
                    if (width <= price.width[i]) {
                        widthIndex = i;
                        break;
                    }
                }
                for (let i = 0; i < price.height.length; i++) {
                    if (height <= price.height[i]) {
                        heightIndex = i;
                        break;
                    }
                }
                let sum = 0;
                if (price[position.color] == undefined) {
                    sum = price['size'][heightIndex][widthIndex];
                } else {
                    if (price[position.color].price == false) {
                        return price[position.color].message[rootState.langActive];
                    } else {
                        sum = price[position.color][heightIndex][widthIndex];
                    }
                }

                if (width < min_width || height < min_height) {
                    if (width >= 100 && height >= 1000) {
                        if (rootState.langActive == 'ru') {
                            return 'Указаны неверные размеры.<br>Минимальные размеры: ширина - ' + min_width + 'мм, высота - ' + min_height + 'мм.<br>Максимальные размеры: ширина - ' + max_width + 'мм, высота - ' + max_height + 'мм.'
                        } else {
                            return 'Вказано невірні розміри.<br>Мінімальні розміри: ширина - ' + min_width + 'мм, висота - ' + min_height + 'мм.<br>Максимальні розміри: ширина - ' + max_width + 'мм, висота - ' + max_height + 'мм.'
                        }

                    }

                }
                if (width > max_width || height > max_height) {
                    if (rootState.langActive == 'ru') {
                        return 'Указаны неверные размеры.<br>Минимальные размеры: ширина - ' + min_width + 'мм, высота - ' + min_height + 'мм.<br>Максимальные размеры: ширина - ' + max_width + 'мм, высота - ' + max_height + 'мм.'
                    } else {
                        return 'Вказано невірні розміри.<br>Мінімальні розміри: ширина - ' + min_width + 'мм, висота - ' + min_height + 'мм.<br>Максимальні розміри: ширина - ' + max_width + 'мм, висота - ' + max_height + 'мм.'
                    }
                }
                if (sum == 0) {
                    if (rootState.langActive == 'ru') {
                        return 'Данный размер недоступен для заказа.'
                    } else {
                        return 'Даний розмір не доступний для замовлення.'
                    }
                }
                return false;
            } catch (error) {
                return false;
            }

        }
    },
    actions: {
        loadRolletPrice({
            commit,
            state
        }) {
            axios.get('https://calculator.gant.ua/api/rollet.php').then((response) => {
                commit('updatePrice', response.data);
                commit('setDefaultValue', response.data);
                if (Object.keys(state.position.remotes).length == 0) {
                    let remotes = {};
                    try {
                        let remotes = state.price.remote;
                        let remotesKey = Object.keys(remotes);
                        for (let i = 0; i < remotesKey.length; i++) {
                            remotes[remotesKey[i]]['count'] = 0;
                        }
                        state.position.remotes = remotes;
                    } catch (error) {
                        console.log(error);
                        remotes = {};
                    }
                }
            });
        },
        loadRolletPosition({
            commit
        }) {

        }

    },
    namespaced: true
};