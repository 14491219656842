<template>
  <div class="popup" id="settings">
    <div class="popup-wrap">
      <span class="h3">Настройки </span>
      <div class="curr-block">
        <div class="del-flex">
          <input
            class="input-control"
            id="cards-shut"
            type="checkbox"
            v-model="checkedHideCards"
          />
          <label for="cards-shut">
            <div class="check-square">
              <div class="check-square-fill"></div>
              <div class="check-square-act"></div>
            </div>
            <span class="txt" v-html="getTranslate('settingCompact', $store.state.langActive)"></span>
          </label>
        </div>
        <div class="del-flex">
          <input
            class="input-control"
            id="calc-currency"
            type="checkbox"
            v-model="checkedConvert"
          />
          <label for="calc-currency">
            <div class="check-square">
              <div class="check-square-fill"></div>
              <div class="check-square-act"></div>
            </div>
            <span class="txt">{{
              getTranslate("settingCurrency", $store.state.langActive)
            }}</span>
          </label>
        </div>
      </div>
      <button class="btn" @click="saveSettings">СОХРАНИТЬ</button>
      <img
        class="close_all set-x cursor-point"
        src="/icons/cloz.svg"
        @click="$store.commit('popups/toggleSettings')"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapState({
      currencyConvert: (state) => state.currencyConvert,
      hideCards: (state) => state.hideCards,
      ...mapGetters("locale", ["getTranslate"]),
    }),
    checkedConvert: {
      set(val) {
        this.updateConvert(val);
      },
      get() {
        return this.currencyConvert;
      },
    },
    checkedHideCards: {
      set(val) {
        this.updateHideCards(val);
      },
      get() {
        return this.hideCards;
      },
    },
  },
  methods: {
    updateConvert(val) {
      this.$store.commit("setCheckedConvert", val);
    },
    updateHideCards(val) {
      this.$store.commit("setHideCards", val);
    },
    saveSettings() {
      localStorage.setItem("currencyConvert", this.currencyConvert);
      localStorage.setItem("hideCards", this.hideCards);
      this.$store.commit("popups/toggleSettings");
    },
  },
};
</script>

<style scoped>
.popup-wrap {
  width: max-content !important;
}
</style>