import axios from 'axios'
import VueAxios from 'vue-axios'

export const InsulatedRollet = {
    state: () => ({
        cardsState: {
            size: false,
            profile: false,
            mounting: false,
            color: false,
            delivery: false,
            control: false,
            remote: false,
        },
        position: {
            width: 0,
            height: 0,
            color: '',
            delivery: '',
            control: '',
            checkedControl: [],
            accInput: {},
            remotes: {},
            discount: 0,
            outsetText: {
                height: 0,
                width: 0
            },
            name: ''
        },
        edit: false,
        editID: 0,
        inOrder: false,
        view: false,
        price: {}

    }),
    mutations: {
        toggleCard(state, key) {
            state.cardsState[key] = !state.cardsState[key];
        },
        viewPosition(state, data) {
            state.position = data.position;
            state.editID = data.id;
            state.view = true;
        },
        toggleIsView(state) {
            state.view = false;
        },
        updatePrice(state, price) {
            state.price = price;
        },
        toggleIsEdit(state, id) {
            state.edit = false;
            state.editID = 0;
        },
        toggleInOrder(state) {
            state.inOrder = true;
        },
        editPosition(state, data) {
            state.position = data.position;
            state.editID = data.id;
            state.edit = true;
            state.inOrder = false
        },
        setHideCards(state, val) {
            state.cardsState = {
                size: val,
                profile: val,
                mounting: val,
                color: val,
                delivery: val,
                control: val,
                remote: val,
            };
        },
        setPosition(state, position) {
            state.position = position;
        },
        remoteDec(state, key) {
            if (state.position.remotes[key]['count'] > 0) {
                state.position.remotes[key]['count'] -= 1;
            }

        },
        remoteInc(state, key) {
            state.position.remotes[key]['count'] += 1;
        },
        setPositionValue(state, data) {
            if (isNaN(data.value)) {
                state.position[data.key] = data.value;
            } else {
                state.position[data.key] = Number(data.value);
            }

        },
        setDefaultValue(state, data) {
            let inputAcc = {};
            try {
                inputAcc = state.position.accInput;
                let inputAccKey = Object.keys(state.position.accInput);
                for (let i = 0; i < inputAccKey.length; i++) {
                    inputAcc[inputAccKey[i]] = 0;
                }
            } catch (error) {
                inputAcc = {};
            }
            let remotes = {};
            try {
                let remotes = state.price.remote;
                let remotesKey = Object.keys(remotes);
                for (let i = 0; i < remotesKey.length; i++) {
                    remotes[remotesKey[i]]['count'] = 0;
                }
            } catch (error) {
                console.log(error);
                remotes = {};
            }
            let position = localStorage.getItem('insulated_rollet');
            if (position != undefined) {
                state.position = JSON.parse(position);
            } else {
                state.position = {
                    width: 0,
                    height: 0,
                    color: Object.keys(data.colors)[0],
                    delivery: Object.keys(data.delivery)[0],
                    control: Object.keys(data.control)[0],
                    checkedControl: [],
                    accInput: inputAcc,
                    remotes: remotes,
                    discount: 0,
                    outsetText: {
                        height: 0,
                        width: 0
                    },
                    name: ''
                };
            }

        },
        clearForm(state) {
            let inputAcc = {};
            try {
                inputAcc = state.position.accInput;
                let inputAccKey = Object.keys(state.position.accInput);
                for (let i = 0; i < inputAccKey.length; i++) {
                    inputAcc[inputAccKey[i]] = 0;
                }
            } catch (error) {
                inputAcc = {};
            }
            let remotes = {};
            try {
                remotes = state.price.remote;
                let remotesKey = Object.keys(remotes);
                for (let i = 0; i < remotesKey.length; i++) {
                    remotes[remotesKey[i]]['count'] = 0;
                }
            } catch (error) {
                remotes = {};
            }
            state.position = {
                width: 0,
                height: 0,
                color: Object.keys(state.price.colors)[0],
                delivery: Object.keys(state.price.delivery)[0],
                control: Object.keys(state.price.control)[0],
                checkedControl: [],
                accInput: inputAcc,
                remotes: remotes,
                discount: 0,
                outsetText: {
                    height: 0,
                    width: 0
                },
                name: ''
            };
            state.inOrder = false;
            localStorage.removeItem('insulated_rollet');
        },
        setOffset(state, data) {
            state.position.outsetText[data.key] = data.value;
        },
        setCheckedElements(state, value) {
            state.position.checkedControl = value;
        },
        setAccInput(state, data) {
            state.position.accInput[data.key] = Number(data.value);
        }
    },
    getters: {
        colorImage: state => {
            try {
                return state.price.colors[state.position.color].image;
            } catch (error) {
                return '';
            }

        },
        getID: state => {
            if (state.edit) {
                return state.editID;
            } else {
                return Date.now();
            }
        },
        deliveryImage: state => {
            try {
                return state.price.delivery[state.position.delivery].image;
            } catch (error) {
                return '';
            }

        },
        priceMessage: state => {
            try {
                let position = state.position;
                let price = state.price;
                if (price.price[position.profile][position.control][position.color].price == false) {
                    return true;
                }
            } catch (error) {
                return false;
            }

        },
        positionPrice: (state, getters, rootState) => {
            let position = {};
            let price = {};
            let min_width = 0;
            let min_height = 0;
            let max_width = 0;
            let max_height = 0;
            let width = 0;
            let height = 0;
            let sum = 0;
            try {
                position = state.position;
                price = state.price.price;
                min_width = state.price.sizes[position.control]['min_width'];
                min_height = state.price.sizes[position.control]['min_height'];
                max_width = state.price.sizes[position.control]['max_width'];
                max_height = state.price.sizes[position.control]['max_height'];
                width = position.width;
                height = position.height;
            } catch (error) {

                return {
                    eur: {
                        sum: 0,
                        sumWithBox: 0
                    },
                    ua: {
                        sum: 0,
                        sumWithBox: 0
                    }
                }
            }
            localStorage.setItem('insulated_rollet', JSON.stringify(position));

            state.position.height_offset = height + 195;
            state.position.width_offset = width;

            if (width >= min_width && height >= min_height) {
                if (height > max_height || width > max_width) {
                    let sum = 0;
                    return {
                        eur: {
                            sum: 0,
                            sumWithBox: 0
                        },
                        ua: {
                            sum: 0,
                            sumWithBox: 0
                        }
                    }
                }

                sum += (width / 1000 - 0.09) * ((state.position.height_offset - 115) / 39) * price.profile;

                sum += (width / 1000 - 0.09) * 1 * price.limit_switch;

                sum += (width / 1000 - 0.09) * 1 * price.shaft;

                sum += (width / 1000 - 0.03) * 1 * price.box;

                sum += (state.position.height_offset / 1000 - 0.195) * 2 * price.bus;

                sum += (width / 1000 - 0.09) * 1 * price.insert_into_end_cap;

                sum += (width / 1000 - 0.03) * 1 * price.adapter;

                sum += (width / 1000 - 0.03) * 1 * price.insulation;

                //  Бокові кришки
                sum += 1 * price.side_caps;
                // Капсула
                sum += 1 * price.capsule;
                // Підшипник
                sum += 1 * price.bearing;
                // Підвіс
                sum += Math.ceil(width / 500) * price.suspension;
                // Боковий замок
                sum += ((state.position.height_offset - 115) / 39) * price.side_lock;
                // Утеплювач бокової кришки
                sum += 2 * price.insulating_side_cover;
                // Супорт підшипника
                sum += 2 * price.bearing_support;
                // Диск захисна пластина
                sum += 1 * price.disk_dry_plate;
                // Накладки (стопор) направляючих
                sum += 2 * price.guide_linings_stopper;
                // Зєднувач короба з рамою
                sum += 2 * price.connector_of_the_box_with_the_frame;
                // Шуруп для направляючої
                sum += 1 * price.guide_screw;
                // Шуруп короба довгий
                sum += 1 * price.the_box_screw_is_long;
                // Шуруп короба короткий
                sum += 1 * price.the_box_screw_is_short;


                if (position.control == 'automatic') {
                    // Мотор 10Нм
                    sum += 1 * price.motor;
                } else {
                    // Направляюча ленти
                    sum += 1 * price.guiding_the_lazy;
                    // Укладчик з лентою
                    sum += 1 * price.stacker_with_tape;
                    // Шків 130 мм
                    sum += 1 * price.pulley_130_mm;
                }


                if (state.price.control[position.control].accessories) {
                    let checked = state.position.checkedControl
                    for (let i = 0; i < checked.length; i++) {
                        sum += state.price.accessories[checked[i]].price;
                    }
                    let inputAcc = state.position.accInput;
                    let inputAccKey = Object.keys(state.position.accInput);
                    for (let i = 0; i < inputAccKey.length; i++) {
                        sum += state.price.accessories[inputAccKey[i]].price * inputAcc[inputAccKey[i]];
                    }
                }

                // state.position.remotes[key]['count'] -= 1;
                let remotes;
                try {
                    remotes = state.position.remotes;
                    let remotesKey = Object.keys(remotes);
                    for (let i = 0; i < remotesKey.length; i++) {
                        sum += remotes[remotesKey[i]]['count'] * remotes[remotesKey[i]]['price'];
                    }
                } catch (error) {
                    remotes = {};
                }

                sum = sum * (1 - position.discount / 100);
                let box = 0;
                // if (width <= 3000) {
                //     if (position.delivery == 'cardboard') {
                //         box = state.price.delivery[position.delivery].price * Math.round(width / 1000);
                //     } else {
                //         box = state.price.delivery[position.delivery].price * width / 1000;
                //     }
                // } else {
                //     if (position.delivery == 'cardboard') {
                //         box = state.price.delivery[position.delivery].price_over * width / 1000;
                //     } else {
                //         box = state.price.delivery[position.delivery].price * width / 1000;
                //     }
                // }
                
                box = state.price.delivery[position.delivery].price * width / 1000;

                let sumWithBox = sum + box;
                return {
                    eur: {
                        sum: sum,
                        sumWithBox: sumWithBox
                    },
                    ua: {
                        sum: sum * rootState.currency.currency.eur,
                        sumWithBox: sumWithBox * rootState.currency.currency.eur
                    }
                }

            } else {
                let sum = 0;
                return {
                    eur: {
                        sum: 0,
                        sumWithBox: 0
                    },
                    ua: {
                        sum: 0,
                        sumWithBox: 0
                    }
                }
            }
        },
        positionSize: (state, getters, rootState) => {
            return state.position.width + " х " + (state.position.height + 195) + " мм <span style='color: #ee3124'>(вікно = " + state.position.width + "/" + state.position.height + "мм)</span>";
        },

        relatedProducts: (state, getters, rootState) => {
            try {
                return rootState.products.products.filter(product => product.category_id == Number(state.price.related_category_id));
            } catch (error) {
                return []
            }

        },
        showAlert: (state, getters, rootState) => {
            try {
                let width = state.position.width;
                let height = state.position.height;

                let min_width = state.price.sizes[state.position.control]['min_width'];
                let min_height = state.price.sizes[state.position.control]['min_height'];
                let max_width = state.price.sizes[state.position.control]['max_width'];
                let max_height = state.price.sizes[state.position.control]['max_height'];

                if (height < 100 || width < 100) {
                    return false;
                }


                if (width < min_width || height < min_height) {
                    if (width >= 100 && height >= 100) {
                        if (rootState.langActive == 'ru') {
                            return 'Указаны неверные размеры.<br>Минимальные размеры: ширина - ' + min_width + 'мм, высота - ' + min_height + 'мм.<br>Максимальные размеры: ширина - ' + max_width + 'мм, высота - ' + max_height + 'мм.'
                        } else {
                            return 'Вказано невірні розміри.<br>Мінімальні розміри: ширина - ' + min_width + 'мм, висота - ' + min_height + 'мм.<br>Максимальні розміри: ширина - ' + max_width + 'мм, висота - ' + max_height + 'мм.'
                        }

                    }

                }
                if (width > max_width || height > max_height) {
                    if (rootState.langActive == 'ru') {
                        return 'Указаны неверные размеры.<br>Минимальные размеры: ширина - ' + min_width + 'мм, высота - ' + min_height + 'мм.<br>Максимальные размеры: ширина - ' + max_width + 'мм, высота - ' + max_height + 'мм.'
                    } else {
                        return 'Вказано невірні розміри.<br>Мінімальні розміри: ширина - ' + min_width + 'мм, висота - ' + min_height + 'мм.<br>Максимальні розміри: ширина - ' + max_width + 'мм, висота - ' + max_height + 'мм.'
                    }
                }

                return false;
            } catch (error) {
                return false;
            }

        }
    },
    actions: {
        loadInsulatedRolletPrice({
            commit,
            state
        }) {
            axios.get('https://calculator.gant.ua/api/insulated-rollet.php').then((response) => {
                commit('updatePrice', response.data);
                commit('setDefaultValue', response.data);
                if (Object.keys(state.position.remotes).length == 0) {
                    let remotes = {};
                    try {
                        let remotes = state.price.remote;
                        let remotesKey = Object.keys(remotes);
                        for (let i = 0; i < remotesKey.length; i++) {
                            remotes[remotesKey[i]]['count'] = 0;
                        }
                        state.position.remotes = remotes;
                    } catch (error) {
                        console.log(error);
                        remotes = {};
                    }
                }
            });
        },
        loadRolletPosition({
            commit
        }) {

        }

    },
    namespaced: true
};