import axios from 'axios'
import VueAxios from 'vue-axios'
export const Currency = {
    state: () => ({
        currency: {
            usd: 0,
            eur: 0,
            cross: 0
        },
        date: ''
    }),
    mutations: {
        updateCurrency(state, data) {
            state.currency.usd = Number(data.usd);
            state.currency.eur = Number(data.eur);
            state.currency.cross = Number(data.cross);
            state.date = data.date;
        },
    },
    actions: {
        loadCurrency({ commit }) {
            axios.get('https://calculator.gant.ua/api/currency.php').then((response) => {
                commit('updateCurrency', response.data);
            });
        }
    },
    namespaced: true
}