<template>
  <div class="main-wrap" id="rollets">
    <div class="main-content">
      <div class="main-cards-wrap">
        <span class="h2 h2-1">
          {{
            getTranslate(
              "insulatedRolletCalcTitlePage",
              $store.state.langActive
            )
          }}
        </span>
        <div class="alert_error" v-if="showAlert" v-html="showAlert"></div>
        <div class="cards-grid">
          <div class="card" :class="{ 'card-disabled': cardsState.size }">
            <input class="acc-toggle" type="checkbox" name="" />
            <label
              class="card-head"
              @click="$store.commit('insulated_rollet/toggleCard', 'size')"
            >
              <span class="h3">
                {{ getTranslate("sizes", $store.state.langActive) }}
              </span>
              <div class="shevron-red"></div>
            </label>
            <div class="card-body">
              <label for="size-w">
                {{ getTranslate("windowWidth", $store.state.langActive) }}
              </label>

              <input
                class="input"
                id="size-w"
                type="number"
                placeholder="2000"
                v-bind:value="position.width"
                @input="updateWidth"
              />

              <label for="size-h">
                {{ getTranslate("windowHeight", $store.state.langActive) }}
              </label>

              <input
                class="input"
                id="size-h"
                type="number"
                v-bind:value="position.height"
                @input="updateHeight"
                placeholder="2000"
                required
              />

              <div class="sizes-wrap">
                <div class="s-width">
                  <div class="shevron-black"></div>
                  <span class="h3">{{ position.width }} мм</span>
                </div>
                <div class="s-height">
                  <div class="shevron-black"></div>
                  <span class="h3">{{ position.height }} мм</span>
                </div>
              </div>
            </div>
          </div>

          <div class="card" :class="{ 'card-disabled': cardsState.color }">
            <div
              class="card-head"
              @click="$store.commit('insulated_rollet/toggleCard', 'color')"
            >
              <span class="h3">{{
                getTranslate("color", $store.state.langActive)
              }}</span>
              <div class="shevron-red"></div>
            </div>
            <div class="card-body">
              <div class="color-grid">
                <div
                  class="color-grid-elem"
                  v-for="(value, key) in price.colors"
                  :key="key"
                >
                  <input
                    type="radio"
                    class="prof-radio"
                    :id="key"
                    name="color"
                    :value="key"
                    :checked="position.color == key"
                    @change="updateColor"
                  />
                  <label class="" :for="key">
                    <div
                      class="prof-img"
                      :style="{ 'background-color': value.hex }"
                    >
                      <!-- <img src="/img/ofset2.png" alt=""> -->
                    </div>
                    <span class="txt">{{
                      value.title[$store.state.langActive]
                    }}</span>
                  </label>
                </div>
              </div>
              <div class="color-ex">
                <img :src="'img/' + colorImage" alt="" />
              </div>
            </div>
          </div>

          <div class="card" :class="{ 'card-disabled': cardsState.control }">
            <div
              class="card-head"
              @click="$store.commit('insulated_rollet/toggleCard', 'control')"
            >
              <span class="h3">{{
                getTranslate("control", $store.state.langActive)
              }}</span>
              <div class="shevron-red"></div>
            </div>
            <div class="card-body control">
              <label for="type">{{
                getTranslate("checkControlType", $store.state.langActive)
              }}</label>
                <select
                  name="type"
                  id="type"
                  @change="updateControl"
                  :value="position.control"
                >
                  <option
                    :value="key"
                    v-for="(value, key) in price.control"
                    :key="key"
                  >
                    {{ value.title[$store.state.langActive] }}
                  </option>
                </select>

              <template v-if="price.control?.[position.control].accessories">
                <div v-for="(value, key) in price.accessories" :key="key">
                  <div class="del-flex" v-if="value.type == 'checkbox'">
                    <input
                      class="input-control"
                      :id="key"
                      type="checkbox"
                      :name="key"
                      :value="key"
                      v-model="checkedAcc"
                      :disabled="!price.control[position.control].accessories"
                    />
                    <label :for="key">
                      <div class="check-square">
                        <div class="check-square-fill"></div>
                        <div class="check-square-act"></div>
                      </div>
                      <span class="txt-norm">{{
                        value.title[$store.state.langActive]
                      }}</span>
                    </label>
                  </div>
                  <div v-if="value.type == 'input'" class="input_acc">
                    <label for="dopoln">{{
                      value.title[$store.state.langActive]
                    }}</label>
                    <input
                      class="input"
                      :id="key"
                      type="number"
                      :name="key"
                      :value="position.accInput[key]"
                      @input="setAccInput"
                      :disabled="!price.control[position.control].accessories"
                    />
                    <!-- test -->
                  </div>
                </div>
              </template>
              <div class="color-ex" v-else>
                <img :src="'img/manual_control.jpg'" alt="" />
              </div>
            </div>
          </div>

          <div
            class="card card-full-width"
            :class="{ 'card-disabled': cardsState.remote }"
            v-if="position.control == 'automatic'"
          >
            <div
              class="card-head"
              @click="$store.commit('insulated_rollet/toggleCard', 'remote')"
            >
              <span class="h3">{{
                getTranslate("remoteType", $store.state.langActive)
              }}</span>
              <div class="shevron-red"></div>
            </div>
            <div class="card-body">
              <div class="prof-grid prof-grid--fw">
                <template v-for="(value, key) in position.remotes" :key="key">
                  <div class="remote-keys">
                    <div class="prof-img">
                      <img :src="'img/' + value.image" alt="" />
                    </div>
                    <span class="h3">{{ key }}</span>
                    <div class="remote-quantity">
                      <div class="minus" @click="remoteDec(key)">—</div>
                      <span class="quantity">{{ value.count }}</span>
                      <div class="plus" @click="remoteInc(key)">+</div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div class="card" :class="{ 'card-disabled': cardsState.delivery }">
            <div
              class="card-head"
              @click="$store.commit('insulated_rollet/toggleCard', 'delivery')"
            >
              <span class="h3">ДОСТАВКА</span>
              <div class="shevron-red"></div>
            </div>
            <div class="card-body">
              <div class="delivery">
                <div
                  class="del-flex"
                  v-for="(value, key) in price.delivery"
                  :key="key"
                >
                  <input
                    type="radio"
                    class="del-radio"
                    :id="key"
                    name="delivery"
                    :value="key"
                    :checked="position.delivery == key"
                    :disabled="
                      position.profile == 'TAP77' && key == 'cardboard'
                    "
                    @change="updateDelivery"
                  />
                  <label class="" :for="key">
                    <div class="check-round">
                      <div class="check-round-act"></div>
                    </div>
                    <span class="txt-norm">{{
                      value.title[$store.state.langActive]
                    }}</span>
                  </label>
                </div>
              </div>
              <div class="color-ex">
                <img :src="'img/' + deliveryImage" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <related-products :type="'rollet'"></related-products>
    </div>

    <div class="sticky-wrap" id="order" ref="orderRollet">
      <div class="stick-block">
        <span class="h3">ХАРАКТЕРИСТИКИ</span>
        <div class="flex-1">
          <div class="flex-2">
            <span>
              <b>{{ getTranslate("sizes", $store.state.langActive) }}: </b>
              <span style="display: inline" v-html="positionSize"></span>
            </span>
          </div>

          <div class="flex-3">
            <span class="last-feat" v-if="position.color != ''">
              <b>{{ getTranslate("color", $store.state.langActive) }}:</b>

              {{
                price.colors[position.color].title[
                  $store.state.langActive
                ].toLowerCase()
              }}
            </span>
          </div>
        </div>

        <div class="flex-1">
          <div class="flex-2">
            <label for="position">
              {{ getTranslate("positionName", $store.state.langActive) }}:
            </label>

            <input
              class="input"
              id="position"
              type="text"
              :value="position.name"
              @input="updateTitle"
            />
          </div>
        </div>

        <span class="h3 h3-price">
          {{ getTranslate("positionPrice", $store.state.langActive) }}:
        </span>

        <span class="price">
          {{ convert(positionPrice) }}
          {{ $store.getters.getCurrencyIcon }}
        </span>

        <div class="flex-1-1">
          <template v-if="!view">
            <button
              v-if="!edit && inOrder"
              class="btn"
              :class="{ btn__incart: inOrder }"
            >
              {{ getTranslate("inOrder", $store.state.langActive) }}
            </button>

            <button
              v-else
              class="btn"
              :class="{ btn__incart: inOrder }"
              @click="
                $store.commit('addToOrder', {
                  type: 'insulated_rollet',
                  position: position,
                  discount: 0,
                  price: positionPrice,
                  id: getID,
                  edit: edit,
                });
                $store.commit('insulated_rollet/toggleInOrder');
                $store.commit('insulated_rollet/toggleIsEdit');
              "
            >
              <template v-if="edit">{{
                getTranslate("updateOrder", $store.state.langActive)
              }}</template>
              <template v-if="!edit && !inOrder">{{
                getTranslate("addToOrder", $store.state.langActive)
              }}</template>
              <template v-if="!edit && inOrder">{{
                getTranslate("inOrder", $store.state.langActive)
              }}</template>
            </button>
          </template>

          <template v-else>
            <a
              href="#"
              class="prev"
              @click.prevent="
                $store.commit('insulated_rollet/toggleIsView');
                $router.push('/order/' + editID);
              "
              >Назад</a
            >
          </template>
          <button
            class="btn-clear"
            @click="
              $store.commit('insulated_rollet/toggleIsView');
              $store.commit('insulated_rollet/clearForm');
            "
          >
            {{ getTranslate("clearForm", $store.state.langActive) }}
          </button>
        </div>

        <div class="plank"></div>
      </div>
    </div>
    <a href="#order" v-if="visible" class="sticky-anchor">
      <span
        >{{ getTranslate("positionPrice", $store.state.langActive) }}:
        {{ convert(positionPrice) }} {{ $store.getters.getCurrencyIcon }}</span
      >
      <div class="white-shevron"></div>
    </a>
  </div>
</template>

<script>
// @ is an alias to /src
import RelatedProducts from "@/components/RelatedProducts";
import { mapState, mapGetters } from "vuex";

export default {
  name: "InsulatedRollet",
  components: {
    RelatedProducts,
  },
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    const options = {
      rootMargin: "0px",
      threshold: 0.1,
    };
    const callback = (entries, observer) => {
      if (entries[0].isIntersecting) {
        this.visible = false;
      } else {
        if (window.innerWidth <= 768) {
          this.visible = true;
        } else {
          this.visible = false;
        }
      }
    };
    const observer = new IntersectionObserver(callback, options);
    observer.observe(this.$refs.orderRollet);
  },
  computed: {
    ...mapState({
      cardsState: (state) => state.insulated_rollet.cardsState,
      position: (state) => state.insulated_rollet.position,
      price: (state) => state.insulated_rollet.price,
      edit: (state) => state.insulated_rollet.edit,
      view: (state) => state.insulated_rollet.view,
      editID: (state) => state.insulated_rollet.editID,
      inOrder: (state) => state.insulated_rollet.inOrder,
    }),
    ...mapGetters("insulated_rollet", [
      "colorImage",
      "deliveryImage",
      "positionPrice",
      "positionSize",
      "showAlert",
      "priceMessage",
      "getID",
    ]),
    checkedAcc: {
      set(val) {
        this.updateChecked(val);
      },
      get() {
        return this.position.checkedControl;
      },
    },
    ...mapGetters("locale", ["getTranslate"]),
  },
  methods: {
    convert(price) {
      if (this.$store.state.currencyConvert) {
        return price.ua.sumWithBox.toFixed(2);
      } else {
        return price.eur.sumWithBox.toFixed(2);
      }
    },
    updateWidth(e) {
      this.$store.commit("insulated_rollet/setPositionValue", {
        key: "width",
        value: e.target.value,
      });
    },
    updateHeight(e) {
      this.$store.commit("insulated_rollet/setPositionValue", {
        key: "height",
        value: e.target.value,
      });
    },
    updateProfile(e) {
      // console.log(e.target.value);
      this.$store.commit("insulated_rollet/setPositionValue", {
        key: "profile",
        value: e.target.value,
      });
    },
    updateMounting(e) {
      this.$store.commit("insulated_rollet/setPositionValue", {
        key: "mounting",
        value: e.target.value,
      });
    },
    updateColor(e) {
      this.$store.commit("insulated_rollet/setPositionValue", {
        key: "color",
        value: e.target.value,
      });
    },
    updateDelivery(e) {
      this.$store.commit("insulated_rollet/setPositionValue", {
        key: "delivery",
        value: e.target.value,
      });
    },
    updateControl(e) {
      this.$store.commit("insulated_rollet/setPositionValue", {
        key: "control",
        value: e.target.value,
      });
    },
    updateTitle(e) {
      this.$store.commit("insulated_rollet/setPositionValue", {
        key: "name",
        value: e.target.value,
      });
    },
    updateChecked(val) {
      this.$store.commit("insulated_rollet/setCheckedElements", val);
    },
    setAccInput(e) {
      this.$store.commit("insulated_rollet/setAccInput", {
        key: e.target.name,
        value: e.target.value,
      });
    },
    remoteInc(key) {
      this.$store.commit("insulated_rollet/remoteInc", key);
    },
    remoteDec(key) {
      this.$store.commit("insulated_rollet/remoteDec", key);
    },
  },
};
</script>

<style scoped>
.card-head,
.btn-clear {
  cursor: pointer;
}
.alert_error {
  background: #f13333;
  color: white;
  margin-top: 20px;
  margin-right: 30px;
  padding: 15px;
}
.not_price {
  font-size: 20px !important;
}
.prev {
  font-size: 20px;
  color: #ee3124;
  text-align: center;
  margin: 0 auto;
  display: block;
}

input.del-radio:disabled ~ label > .check-round {
  border-color: #666666 !important;
}
</style>
