<template>
  <div class="popup" id="warning">
    <div class="popup-wrap popup-wrap-warn">
      <span class="txt">{{title}}</span>
      <div class="warn-block">
        <p>
         {{content}}
        </p>
      </div>
      <div class="warn-btn-block">
        <button class="btn">OK</button>
        <button class="btn">HE OK</button>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState, mapGetters } from "vuex";
export default {
    computed: {
    ...mapState({
      title: (state) => state.popups.alertTitle,
      content: (state) => state.popups.alertContent,
    }),
  },
};
</script>

<style scoped>
</style>