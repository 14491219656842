<template>
  <header>
    <div class="head-wrap">
      
      <div class="icon-cont">
        <img
          class="menu-open cursor-point"
          src="/icons/burger.svg"
          @click="$store.commit('toggleMobileMenu')" />
      </div>
      <a href="https://gant.ua" class="logo-wrap" v-if="isWindow">
        <img class="logo" src="/img/logo.svg" />
      </a>
      <div v-else class="foot-icon-wrap">
        <a href="https://apps.apple.com/us/app/gant-calculator/id1572672125" class="app-wrap" target="_blank">
          <img class src="/icons/apple1.svg" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.calculator.gant"
          class="app-wrap app-wrap-g"
          target="_blank"><img class src="/icons/google1.svg" /></a>
      </div>
      <nav class="_mob-f">
        <ul class="top-menu">
          <li class="desktop-hide home-icon">
            <a href="https://gant.ua"><img src="/icons/home.svg" alt="Gant" /></a>
          </li>
          <li>
            <router-link to="/">{{
              getTranslate("products", $store.state.langActive)
              }}</router-link>
          </li>
          <li>
            <router-link to="/rollet">{{
              getTranslate("rolletCalc", $store.state.langActive)
              }}</router-link>
          </li>
          <li>
            <router-link to="/insulated-rollet">
            {{ getTranslate("insulatedRolletCalc", $store.state.langActive) }}
            </router-link>
          </li>
          <li>
            <router-link to="/gate">{{
              getTranslate("gateCalc", $store.state.langActive)
              }}</router-link>
          </li>
          <li>
            <router-link to="/door">{{
              getTranslate("doorCalc", $store.state.langActive)
              }}</router-link>
          </li>
          <li class="desktop-hide" style="display: none">
            <router-link to="/order">{{
              getTranslate("orderList", $store.state.langActive)
              }}</router-link>
          </li>
          <li class="desktop-hide" style="display: none">
            <router-link to="/alerts">{{
              getTranslate("alerts_title", $store.state.langActive)
              }}</router-link>
          </li>
        </ul>
      </nav>

      <div class="icon-cont2">
        <div class="top-menu langs">
          <a
            href="#"
            class="lang"
            v-for="(value, key) in $store.state.langs"
            :key="key"
            :class="{ active: key == $store.state.langActive }"
            @click.prevent="$store.commit('toggleLang', key)">{{
            key.toUpperCase() }}</a>
        </div>
        <a
          href="#"
          class="icon-wrap icon-wrap-set"
          @click="$store.commit('popups/toggleSettings')"></a>
        <a
          href="#"
          class="icon-wrap icon-wrap-curr"
          @click="$store.commit('popups/toggleCurrency')"></a>
        <a
          href="#"
          @click="$router.push('/order')"
          class="icon-wrap icon-wrap-cart"></a>

        <div class="cart-info-cont" @click="$router.push('/order')">
          <span class="cart-info__num">{{ getTranslate("orderCount",
            $store.state.langActive) }} -
            {{ orderCount }}</span>
          <span class="cart-info__sum">{{ getTranslate("orderSum",
            $store.state.langActive) }} -
            {{ orderSumm }} {{ $store.getters.getCurrencyIcon }}</span>
        </div>
      </div>
    </div>
    <div class="head-wrap head-wrap-nav _desk">
      
      <nav>
        <ul class="top-menu">
          <li class="desktop-hide home-icon">
            <a href="https://gant.ua"><img src="/icons/home.svg" alt="Gant" /></a>
          </li>
          <li>
            <router-link to="/">{{
              getTranslate("products", $store.state.langActive)
              }}</router-link>
          </li>
          <li>
            <router-link to="/rollet">{{
              getTranslate("rolletCalc", $store.state.langActive)
              }}</router-link>
          </li>
          <li>
            <router-link to="/insulated-rollet">
            {{ getTranslate("insulatedRolletCalc", $store.state.langActive) }}
            </router-link>
          </li>
          <li>
            <router-link to="/gate">{{
              getTranslate("gateCalc", $store.state.langActive)
              }}</router-link>
          </li>
          <li>
            <router-link to="/sliding-gate">{{
              getTranslate("slidingGateCalc", $store.state.langActive)
              }}</router-link>
          </li>
          <li>
            <router-link to="/swing-gate">{{
              getTranslate("swingGateCalc", $store.state.langActive)
              }}</router-link>
          </li>
          <li>
            <router-link to="/door">{{
              getTranslate("doorCalc", $store.state.langActive)
              }}</router-link>
          </li>
          <li class="desktop-hide" style="display: none">
            <router-link to="/order">{{
              getTranslate("orderList", $store.state.langActive)
              }}</router-link>
          </li>
          <li class="desktop-hide" style="display: none">
            <router-link to="/alerts">{{
              getTranslate("alerts_title", $store.state.langActive)
              }}</router-link>
          </li>
        </ul>
      </nav>
    </div>

    <div
      class="mob__menu"
      @click.prevent="$store.commit('toggleMobileMenu')"
      v-if="mobileMenuShow">
      <div class="mob__menu_wrap" @click.stop>
        <div class="static_mob_menu">
          <img
            class="close_all cursor-point"
            src="/icons/cloz.svg"
            @click="$store.commit('toggleMobileMenu')" />
          <a href="/" class="mob-logo-wrap" v-if="isWindow"><img
              class="mob-logo" src="/img/logo.svg" /></a>
        </div>
        <div class="menu_overflow">
          <div class="menu-top">
            <ul id="top-menu">
              <li>
                <router-link
                  to="/"
                  @click="$store.commit('toggleMobileMenu')">{{
                  getTranslate("products", $store.state.langActive)
                  }}</router-link>
              </li>
              <li>
                <router-link
                  to="/rollet"
                  @click="$store.commit('toggleMobileMenu')">{{
                  getTranslate("rolletCalc", $store.state.langActive)
                  }}</router-link>
              </li>
              <li>
                <router-link to="/insulated-rollet" @click="$store.commit('toggleMobileMenu')">
                {{ getTranslate("insulatedRolletCalc", $store.state.langActive) }}
                </router-link>
              </li>
              <li>
                <router-link
                  to="/gate"
                  @click="$store.commit('toggleMobileMenu')">{{
                  getTranslate("gateCalc", $store.state.langActive)
                  }}</router-link>
              </li>
              <li>
                <router-link
                  to="/sliding-gate"
                  @click="$store.commit('toggleMobileMenu')">{{
                  getTranslate("slidingGateCalc", $store.state.langActive)
                  }}</router-link>
              </li>
              <li>
                <router-link
                  to="/swing-gate"
                  @click="$store.commit('toggleMobileMenu')">{{
                  getTranslate("swingGateCalc", $store.state.langActive)
                  }}</router-link>
              </li>
              <li>
                <router-link
                  to="/door"
                  @click="$store.commit('toggleMobileMenu')"
                  >{{
                    getTranslate("doorCalc", $store.state.langActive)
                  }}</router-link
                >
              </li>

              <li class="calc-point">
                <router-link
                  to="/order"
                  @click="$store.commit('toggleMobileMenu')">{{
                  getTranslate("orderList", $store.state.langActive)
                  }}</router-link>
              </li>
              <li>
                <a
                  href="#"
                  @click.prevent="
                    $store.commit('toggleMobileMenu');
                    $store.commit('popups/toggleSettings');
                  ">{{ getTranslate("settings", $store.state.langActive) }}</a>
              </li>
              <div class="top-menu langs-mobile">
                <a
                  href="#"
                  class="lang"
                  v-for="(value, key) in $store.state.langs"
                  :key="key"
                  :class="{ active: key == $store.state.langActive }"
                  @click.prevent="
                    $store.commit('toggleLang', key);
                    $store.commit('toggleMobileMenu');
                  ">{{ key.toUpperCase() }}</a>
              </div>
            </ul>
          </div>
          <div class="currency-wrap">
            <span>Курс на <span class="curr-date">{{ currencyDate }}</span></span>
            <currency></currency>
          </div>

          <div class="menu-bottom" v-if="deferredPrompt">
            <span>{{
              getTranslate("downloadApp", $store.state.langActive)
              }}</span>
            <div class="icon-wrap">
              <span class="app-wrap" @click="install">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 172 172">
                  <g
                    fill="none"
                    fill-rule="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="none"
                    stroke-miterlimit="none"
                    stroke-dasharray
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal">
                    <path
                      d="M0,172v-172h172v172z"
                      fill="none"
                      fill-rule="nonzero"
                      stroke-linejoin="miter"
                      stroke-miterlimit="10"></path>
                    <g
                      fill="#f13333"
                      fill-rule="evenodd"
                      stroke-linejoin="round"
                      stroke-miterlimit="2">
                      <path
                        d="M77.04167,21.5c0,-6.923 -5.61867,-12.54167 -12.54167,-12.54167h-43c-6.923,0 -12.54167,5.61867 -12.54167,12.54167v43c0,6.923 5.61867,12.54167 12.54167,12.54167h43c6.923,0 12.54167,-5.61867 12.54167,-12.54167z"></path>
                      <path
                        d="M77.04167,107.5c0,-6.923 -5.61867,-12.54167 -12.54167,-12.54167h-43c-6.923,0 -12.54167,5.61867 -12.54167,12.54167v43c0,6.923 5.61867,12.54167 12.54167,12.54167h43c6.923,0 12.54167,-5.61867 12.54167,-12.54167z"></path>
                      <path
                        d="M163.04167,21.5c0,-6.923 -5.61867,-12.54167 -12.54167,-12.54167h-43c-6.923,0 -12.54167,5.61867 -12.54167,12.54167v43c0,6.923 5.61867,12.54167 12.54167,12.54167h43c6.923,0 12.54167,-5.61867 12.54167,-12.54167z"></path>
                      <path
                        d="M123.625,100.33333v57.33333c0,2.967 2.408,5.375 5.375,5.375c2.967,0 5.375,-2.408 5.375,-5.375v-57.33333c0,-2.967 -2.408,-5.375 -5.375,-5.375c-2.967,0 -5.375,2.408 -5.375,5.375z"></path>
                      <path
                        d="M100.33333,134.375h57.33333c2.967,0 5.375,-2.408 5.375,-5.375c0,-2.967 -2.408,-5.375 -5.375,-5.375h-57.33333c-2.967,0 -5.375,2.408 -5.375,5.375c0,2.967 2.408,5.375 5.375,5.375z"></path>
                    </g>
                  </g>
                </svg>
                {{ getTranslate("appInstall", $store.state.langActive) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <template v-if="getAlerts">
    <div class="alert" v-if="getAlerts.length > 0 && getAlerts[0]['preview']">
      <div class="alert-wrapp">
        <span>{{ getAlerts[0].title[$store.state.langActive] }}</span>
        <span class="alert-more"><router-link to="/alerts">{{
            getTranslate("details", $store.state.langActive)
            }}</router-link></span>
      </div>
    </div>
  </template>
</template>

<script>
import Currency from "@/components/Currency";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    Currency,
  },
  computed: {
    ...mapState({
      mobileMenuShow: (state) => state.mobileMenuShow,
      currencyDate: (state) => state.currency.date,
      deferredPrompt: (state) => state.deferredPrompt,
    }),
    ...mapGetters(["orderCount", "orderSumm", "getAlerts"]),
    ...mapGetters("locale", ["getTranslate"]),
    isWindow: function () {
      let isInIFrame = window.location != window.parent.location ? false : true;
      return isInIFrame;
    },
  },
  methods: {
    async install() {
      this.deferredPrompt.prompt();
    },
  },
};
</script>

<style scoped>
.alert {
  background: #f13333;
  color: #fff;
  padding: 10px 20px;
}
.alert-wrapp {
  max-width: 1330px;
  margin: 0 auto;
}
.alert-more {
  margin-left: 20px;
}
.alert-more a {
  font-weight: 600 !important;
  color: #fff;
}
.lang,
.langs-mobile {
  margin-right: 20px !important;
}
.langs-mobile .lang {
  margin-bottom: 0 !important;
}
.foot-icon-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.foot-icon-wrap img {
  width: 100px;
}
@media screen and (max-width: 768px) {
  .foot-icon-wrap {
    display: none;
  }
}
.app-wrap {
  display: flex !important;
  align-items: center;
  width: 100% !important;
}

.app-wrap svg {
  width: 25px;
  margin-right: 5px;
}

.home-icon img {
  max-width: 30px;
  width: 30px;
  display: block;
}
.home-icon a {
  display: block;
}
</style>