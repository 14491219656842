<template>
  <div class="main-wrap" id="products">
    <span class="h2 h2-1">{{
      getTranslate("products", $store.state.langActive)
    }}</span>
    <div class="prod-inner">
      <div class="sticky-wrap" id="category-stick" ref="categories">
        <div class="stick-block">
          <span class="h3">{{
            getTranslate("categories", $store.state.langActive).toUpperCase()
          }}</span>
          <div class="flex-1">
            <div class="flex-2">
              <div class="cat-elem">
                <input
                  type="radio"
                  class="cat-radio"
                  id="all-prods"
                  name="category"
                  value="all"
                  @change="setFilter"
                  checked
                />
                <label class="h4" for="all-prods">{{
                  getTranslate("allProducts", $store.state.langActive)
                }}</label>
              </div>
              <template
                v-for="(category, key) in $store.state.products.categories"
                :key="key"
              >
                <div class="cat-elem">
                  <input
                    type="radio"
                    class="cat-radio"
                    :id="'category_' + category.id"
                    name="category"
                    :value="category.id"
                    @change="setFilter"
                  />
                  <label class="" :for="'category_' + category.id"
                    >{{ category.name[$store.state.langActive] }}
                  </label>
                </div>
              </template>
            </div>

            <div class="plank"></div>
          </div>
        </div>
      </div>

      <div class="main-content main-content-prod">
        <input
          class="search"
          type="search"
          :placeholder="getTranslate('search', $store.state.langActive)"
          :value="$store.state.searchQuery"
          @input="setQuery"
        />

        <div class="main-cards-wrap">
          <template v-if="$store.getters.filterCategory.length">
            <div class="cards-grid cards-grid-prods">
              <transition-group name="products">
                <div
                  class="prod-card products-item"
                  v-for="(product, key) in $store.getters.paginateProducts"
                  :key="key"
                >
                  <div class="prod-card-main">
                    <a
                      href=""
                      class="prod-info"
                      @click.prevent="
                        $store.commit('popups/setActiveProduct', product.id)
                      "
                      ><img src="/icons/info.svg" alt=""
                    /></a>
                    <a
                      :href="
                        getTranslate('viewPartnersURL', $store.state.langActive)
                      "
                      target="_blank"
                      class="prod-view"
                      ><img src="/icons/view.svg" alt=""
                    /></a>
                    <div class="prod-img-wrap">
                      <img
                        :src="product.photos[0]"
                        alt=""
                        v-if="product.photos[0] != undefined"
                      />
                      <img src="/img/no-image.png" alt="" v-else />
                    </div>
                    <span class="prod-name" :title="product.title[$store.state.langActive]">{{
                      product.title[$store.state.langActive]
                    }}</span>
                    <span class="price"
                      >{{ convert(product.price) }}
                      {{ $store.getters.getCurrencyIcon }}</span
                    >
                  </div>
                  <button
                    class="btn"
                    :class="{
                      btn__incart: inOrder(product.id),
                    }"
                    @click="
                      addToOrder({
                        id: product.id,
                        title: product.title[$store.state.langActive],
                        discount: 0,
                        price: {
                          eur: product.price,
                          ua: (
                            product.price * $store.state.currency.currency.eur
                          ).toFixed(2),
                        },
                        type: 'product',
                      })
                    "
                  >
                    <template v-if="!inOrder(product.id)">{{
                      getTranslate("addToOrder", $store.state.langActive)
                    }}</template>
                    <template v-if="inOrder(product.id)">{{
                      getTranslate("inOrder", $store.state.langActive)
                    }}</template>
                  </button>
                </div>
              </transition-group>
            </div>
          </template>
          <div v-else>
            <p class="no-found">
              {{ getTranslate("productsNotFound", $store.state.langActive) }}
            </p>
          </div>
          <div
            class="pagination"
            v-show="$store.getters.filterCategory.length > 0"
          >
            <button
              :disabled="$store.state.page == 0"
              @click="
                $store.commit('decrementPage');
                scrollToElement();
              "
            >
              Назад
            </button>
            <span
              >{{ getTranslate("page", $store.state.langActive) }}
              {{ $store.state.page + 1 }}
              {{ getTranslate("from", $store.state.langActive) }}
              {{
                $store.getters.pageCount - 1 == 0 ? 1 : $store.getters.pageCount
              }}</span
            >
            <button
              :disabled="
                $store.getters.pageCount - 1 == $store.state.page ||
                $store.getters.pageCount - 1 == 0
              "
              @click="
                $store.commit('incrementPage');
                scrollToElement();
              "
            >
              Вперед
            </button>
          </div>
        </div>
      </div>
    </div>
    <a href="#category-stick" v-if="visible" class="sticky-anchor">
      <span>{{ getTranslate("categories", $store.state.langActive) }}</span>
      <div class="white-shevron"></div>
    </a>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    const options = {
      rootMargin: "0px",
      threshold: 0.1,
    };
    const callback = (entries, observer) => {
      if (entries[0].isIntersecting) {
        this.visible = false;
      } else {
        if (window.innerWidth <= 768) {
          this.visible = true;
        } else {
          this.visible = false;
        }
      }
    };
    const observer = new IntersectionObserver(callback, options);
    observer.observe(this.$refs.categories);
  },
  computed: {
    ...mapGetters("locale", ["getTranslate"]),
  },
  methods: {
    setQuery(e) {
      this.$store.commit("setSearchQuery", e.target.value);
    },
    inOrder(id) {
      try {
        let products = this.$store.state.order.filter(
          (product) => product.id == id
        );
        if (products.length > 0) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
    setFilter(e) {
      this.scrollToElement();
      this.$store.commit("setFilterCategory", e.target.value);
    },
    scrollToElement() {
      const el = this.$el.getElementsByClassName("prod-inner")[0];

      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    addToOrder(data) {
      this.$store.commit("addToOrder", data);
    },
    convert(price) {
      if (this.$store.state.currencyConvert) {
        return (price * this.$store.state.currency.currency.eur).toFixed(2);
      } else {
        return price.toFixed(2);
      }
    },
  },
};
</script>

<style scoped>
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  cursor: pointer;
}
input[type="search"]:focus {
  outline: none;
}
.prod-inner {
  scroll-margin: 110px;
}
.products-item {
  transition: all 1s;
  display: inline-block;
}
.products-enter, .products-leave-to
/* .products-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.no-found {
  font-size: 24px;
  text-align: center;
  margin-top: 50px;
  font-weight: 700;
  color: #666666 !important;
}
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination button {
  background-color: #f13333;
  padding: 10px 20px;
  color: white;
  box-shadow: none;
  border: none;
  cursor: pointer;
}
.pagination button:disabled {
  background-color: #bdbdbd;
  cursor: not-allowed;
}
.products-item .btn {
  cursor: pointer;
}
</style>