<template>
  <div class="main-wrap" id="gates">
    <div class="main-content">
      <div class="main-cards-wrap">
        <span class="h2 h2-1">{{
          getTranslate("gateCalcTitlePage", $store.state.langActive)
        }}</span>
        <div class="alert_error" v-if="showAlert" v-html="showAlert"></div>
        <div class="cards-grid cards-grid-gates">
          <div class="card" :class="{ 'card-disabled': cardsState.size }">
            <input class="acc-toggle" type="checkbox" name="" />
            <label
              class="card-head"
              for="acc1"
              @click="$store.commit('gate/toggleCard', 'size')"
            >
              <span class="h3">{{
                getTranslate("sizes", $store.state.langActive)
              }}</span>
              <div class="shevron-red"></div>
            </label>
            <div class="card-body">
              <label for="size-w">{{
                getTranslate("positionWidth", $store.state.langActive)
              }}</label>
              <input
                class="input"
                id="size-w"
                type="number"
                name=""
                placeholder="2000"
                v-bind:value="position.width"
                @input="updateWidth"
              />
              <label for="size-h">{{
                getTranslate("positionHeight", $store.state.langActive)
              }}</label>
              <input
                class="input"
                id="size-h"
                type="number"
                name=""
                placeholder="2000"
                v-bind:value="position.height"
                @input="updateHeight"
              />
              <!-- <label for="lintel-h">{{
                getTranslate("Притолока (h), мм", $store.state.langActive)
              }}</label>
              <input
                class="input"
                id="lintel-h"
                type="number"
                name=""
                placeholder="0"
                v-bind:value="position.lintel"
                @input="updateLintel"
              /> -->

              <span class="delta_message" v-if="position.width > 4700">{{
                getTranslate("deltaProfile", $store.state.langActive)
              }}</span>

              <div class="sizes-wrap">
                <div class="s-width">
                  <div class="shevron-black"></div>

                  <span class="h3">{{ position.width }} мм</span>
                </div>
                <div class="s-height">
                  <div class="shevron-black"></div>
                  <span class="h3">{{ position.height }} мм</span>
                </div>
              </div>
            </div>
          </div>

          <div
            class="card card-span16"
            :class="{ 'card-disabled': cardsState.type }"
          >
            <div
              class="card-head"
              @click="$store.commit('gate/toggleCard', 'type')"
            >
              <span class="h3">{{
                getTranslate("gateType", $store.state.langActive)
              }}</span>
              <div class="shevron-red"></div>
            </div>
            <div class="card-body">
              <div class="prof-grid">
                <div
                  class="prof-grid-elem align-left"
                  v-for="(value, key) in price.types"
                  :key="key"
                >
                  <input
                    type="radio"
                    class="prof-radio"
                    :id="key"
                    name="profile"
                    :value="key"
                    :checked="position.type == key"
                    @change="updateType"
                  />
                  <label class="align-left" :for="key">
                    <div class="prof-img prof-img-gates">
                      <span class="label" v-if="value.label">{{ value.label[$store.state.langActive] }}</span>
                      <img :src="'img/' + value.image" alt="" />
                    </div>
                    <span class="h3">{{
                      value.title[$store.state.langActive]
                    }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div
            class="card card-span16 card-lift_type"
            :class="{ 'card-disabled': cardsState.lift_type }"
          >
            <div
              class="card-head"
              @click="$store.commit('gate/toggleCard', 'lift_type')"
            >
              <span class="h3">{{
                getTranslate("gateLiftType", $store.state.langActive)
              }}</span>
              <div class="shevron-red"></div>
            </div>
            <div class="card-body card-body-lift_type">
              <div class="prof-grid">
                <template v-for="(value, key) in price.lifts" :key="key">
                  <div
                    class="prof-grid-elem prof-grid-elem__lift_type"
                    :class="price.types[position.type].lifts.includes(key) ? '' : 'unavailable'"
                  >
                    <div class="rise-type">
                      <div class="prof-img prof-img-lift_type">
                        <img :src="'img/' + value.image" alt="" />
                      </div>
                      <span class="h3 h3-mod">{{
                        value.title[$store.state.langActive]
                      }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div
            class="card card-full-width card-diz"
            :class="{ 'card-disabled': cardsState.design }"
          >
            <div
              class="card-head"
              @click="$store.commit('gate/toggleCard', 'design')"
            >
              <span class="h3">ДИЗАЙН</span>
              <div class="shevron-red"></div>
            </div>
            <div class="card-body card-body-diz">
              <div class="col">
                <span class="h3" style="display: block">{{
                  getTranslate("gateStandartColors", $store.state.langActive)
                }}</span>
                <div class="color-grid color-grid-gates">
                  <template v-for="(value, key) in price.colors" :key="key">
                    <div class="color-grid-elem" v-if="value.markup == 0">
                      <input
                        type="radio"
                        class="prof-radio"
                        :id="key"
                        name="color"
                        :value="key"
                        :checked="position.color == key"
                        @change="updateColor"
                      />
                      <label class="" :for="key">
                        <div
                          class="prof-img"
                          :style="{
                            'background-image':
                              'url(img/' + value.color_image + ')',
                          }"
                        >
                          <!-- <img src="/img/ofset2.png" alt=""> -->
                        </div>
                        <span class="txt">{{
                          value.title[$store.state.langActive]
                        }}</span>
                      </label>
                    </div>
                  </template>
                </div>
                <span class="h3" style="display: block"
                  >{{
                    getTranslate("gateCustomColors", $store.state.langActive)
                  }}
                  (+{{ price.color_markup }}%)</span
                >
                <div class="color-grid color-grid-gates">
                  <template v-for="(value, key) in price.colors" :key="key">
                    <div class="color-grid-elem" v-if="value.markup > 0">
                      <input
                        type="radio"
                        class="prof-radio"
                        :id="key"
                        name="color"
                        :value="key"
                        :checked="position.color == key"
                        @change="updateColor"
                      />
                      <label class="" :for="key">
                        <div
                          class="prof-img"
                          :style="{
                            'background-image':
                              'url(img/' + value.color_image + ')',
                          }"
                        >
                          <!-- <img src="/img/ofset2.png" alt=""> -->
                        </div>
                        <span class="txt">{{
                          value.title[$store.state.langActive]
                        }}</span>
                      </label>
                    </div>
                  </template>
                </div>
              </div>

              <div class="col">
                <template v-if="Object.keys(getDesignOption).length > 0">
                  <div
                    class="del-flex del-flex-gates"
                    v-for="(item, key) in getDesignOption"
                    :key="key"
                  >
                    <input
                      class="input-control"
                      :id="key"
                      type="checkbox"
                      :value="key"
                      v-model="designChecked"
                      :true-value="{ key: key, check: true }"
                      :false-value="{ key: key, check: false }"
                      :disabled="item.disabled"
                    />
                    <label :for="key">
                      <div class="check-square">
                        <div class="check-square-fill"></div>
                        <div class="check-square-act"></div>
                      </div>
                      <span>{{ item.title[$store.state.langActive] }}</span>
                    </label>
                  </div>
                </template>
                <div class="color-ex">
                  <img :src="'img/' + colorImage" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div
            class="card card-full-width"
            :class="{ 'card-disabled': cardsState.accessories }"
          >
            <div
              class="card-head"
              @click="$store.commit('gate/toggleCard', 'accessories')"
            >
              <span class="h3">{{
                getTranslate("gateAccessories", $store.state.langActive)
              }}</span>
              <div class="shevron-red"></div>
            </div>
            <div class="card-body">
              <div class="flex-cont">
                <div class="col">
                  <template
                    v-for="(value, key) in getAccessoriesLeft"
                    :key="key"
                  >
                    <div
                      class="del-flex"
                      v-if="value.type == 'sum' || value.type == 'markup'"
                    >
                      <input
                        class="input-control"
                        :id="key"
                        type="checkbox"
                        v-model="checkedAcc"
                        :checked="activeChecked(key)"
                        :true-value="{ key: key, check: true }"
                        :false-value="{ key: key, check: false }"
                        :name="key"
                        :disabled="value.disabled"
                      />
                      <label :for="key">
                        <div class="check-square">
                          <div class="check-square-fill"></div>
                          <div class="check-square-act"></div>
                        </div>
                        <span class="txt-norm">{{
                          value.title[$store.state.langActive]
                        }}</span>
                      </label>
                    </div>
                    <div
                      class="del-flex"
                      v-if="
                        value.type == 'sum_input' ||
                        value.type == 'sum_width_input'
                      "
                    >
                      <input
                        class="input-control"
                        :id="key"
                        type="checkbox"
                        v-model="checkedAcc"
                        :checked="activeChecked(key)"
                        :true-value="{ key: key, check: true }"
                        :false-value="{ key: key, check: false }"
                        :name="key"
                        @change="checkAccSumInput(key)"
                        :disabled="value.disabled"
                      />
                      <label :for="key">
                        <div class="check-square">
                          <div class="check-square-fill"></div>
                          <div class="check-square-act"></div>
                        </div>
                        <span
                          class="txt-norm"
                          :class="{ red: value.is_primary }"
                          >{{ value.title[$store.state.langActive] }}</span
                        >
                      </label>
                      <input
                        class="addit"
                        :id="key"
                        type="number"
                        :name="key"
                        :value="position.accInput[key]"
                        @input="setAccInput"
                      />
                    </div>
                  </template>
                </div>
                <div class="col">
                  <template
                    v-for="(value, key) in getAccessoriesRight"
                    :key="key"
                  >
                    <div
                      class="del-flex"
                      v-if="value.type == 'sum' || value.type == 'markup'"
                    >
                      <input
                        class="input-control"
                        :id="key"
                        type="checkbox"
                        v-model="checkedAcc"
                        :checked="activeChecked(key)"
                        :true-value="{ key: key, check: true }"
                        :false-value="{ key: key, check: false }"
                        :name="key"
                        :disabled="value.disabled"
                      />
                      <label :for="key">
                        <div class="check-square">
                          <div class="check-square-fill"></div>
                          <div class="check-square-act"></div>
                        </div>
                        <span class="txt-norm">{{
                          value.title[$store.state.langActive]
                        }}</span>
                      </label>
                    </div>
                    <div
                      class="del-flex"
                      v-if="
                        value.type == 'sum_input' ||
                        value.type == 'sum_width_input'
                      "
                    >
                      <input
                        class="input-control"
                        :id="key"
                        type="checkbox"
                        v-model="checkedAcc"
                        :checked="activeChecked(key)"
                        :true-value="{ key: key, check: true }"
                        :false-value="{ key: key, check: false }"
                        :name="key"
                        :disabled="value.disabled"
                        @change="checkAccSumInput(key)"
                      />
                      <label :for="key">
                        <div class="check-square">
                          <div class="check-square-fill"></div>
                          <div class="check-square-act"></div>
                        </div>
                        <span
                          class="txt-norm"
                          :class="{ red: value.is_primary }"
                          >{{ value.title[$store.state.langActive] }}</span
                        >
                      </label>
                      <input
                        class="addit"
                        :id="key"
                        type="number"
                        :name="key"
                        :value="position.accInput[key]"
                        @input="setAccInput"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div
            class="card card-full-width"
            :class="{ 'card-disabled': cardsState.box }"
          >
            <div
              class="card-head"
              @click="$store.commit('gate/toggleCard', 'box')"
            >
              <span class="h3">УПАКОВКА</span>
              <div class="shevron-red"></div>
            </div>
            <div class="card-body control">
              <div class="flex-cont">
                <div class="col">
                  <div
                    class="del-flex"
                    v-for="(value, key) in getBoxOptions"
                    :key="key"
                  >
                    <input
                      class="input-control"
                      :id="key"
                      type="checkbox"
                      :value="key"
                      v-model="boxChecked"
                      :true-value="{ key: key, check: true }"
                      :false-value="{ key: key, check: false }"
                      :disabled="value.disabled"
                    />
                    <label :for="key">
                      <div class="check-square">
                        <div class="check-square-fill"></div>
                        <div class="check-square-act"></div>
                      </div>
                      <span class="txt-norm">{{
                        value.title[position.type][$store.state.langActive]
                      }}</span>
                    </label>
                  </div>
                </div>
                <div class="col">
                  <div class="color-ex">
                    <img
                      v-if="position.box.length > 0"
                      :src="'img/' + price.box[position.box[0]].image"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <related-products v-bind:type="'gate'"></related-products>
    </div>
    <div class="sticky-wrap" id="order2" ref="orderGate">
      <div class="stick-block">
        <span class="h3">ХАРАКТЕРИСТИКИ</span>
        <div class="flex-1">
          <div class="flex-2">
            <span
              ><b>{{ getTranslate("sizes", $store.state.langActive) }}:</b>
              {{ position.width }} х {{ position.height }} мм</span
            >
            <span v-if="isOrderAlert == 1" style="color: #f13333">{{
              getTranslate("gateAlert", $store.state.langActive)
            }}</span>
            
            <span v-if="position.type != ''"
              ><b>{{ getTranslate("gateType", $store.state.langActive) }}:</b>
              {{
                price.types[position.type].title[$store.state.langActive]
              }}</span
            >
          </div>
          <div class="flex-3" v-if="position.color != ''">
            <span
              ><b>{{ getTranslate("color", $store.state.langActive) }}:</b>
              {{
                price.colors[position.color].title[$store.state.langActive]
              }}</span
            >
            <span v-if="!price.stock[position.color]" style="color: #f13333">{{
              getTranslate("stockAlert", $store.state.langActive)
            }}</span>
            <div class="flex-3-1">
              <span class="last-feat">{{
                getTranslate("complection", $store.state.langActive)
              }}</span>
              <img
                id="order-info"
                src="/icons/info.svg"
                alt=""
                @click="$store.commit('popups/toggleComplection')"
              />
            </div>
          </div>
        </div>

        <div class="flex-1">
          <div class="flex-2">
            <label for="position"
              >{{ getTranslate("positionName", $store.state.langActive) }}:
            </label>
            <input
              class="input"
              id="position"
              type="text"
              :value="position.name"
              @input="updateTitle"
            />
          </div>
        </div>

        <span class="h3 h3-price"
          >{{ getTranslate("positionPrice", $store.state.langActive) }}:</span
        >
        <span class="price"
          >{{ convert(positionPrice) }}
          {{ $store.getters.getCurrencyIcon }}</span
        >
        <div class="flex-1-1">
          <template v-if="!view">
            <button
              v-if="!edit && inOrder"
              class="btn"
              :class="{ btn__incart: inOrder }"
            >
              {{ getTranslate("inOrder", $store.state.langActive) }}
            </button>
            <button
              v-else
              class="btn"
              :class="{ btn__incart: inOrder }"
              @click="
                $store.commit('addToOrder', {
                  type: 'gate',
                  discount: 0,
                  position: position,
                  price: positionPrice,
                  id: getID,
                  edit: edit,
                });
                $store.commit('gate/toggleInOrder');
                $store.commit('gate/toggleIsEdit');
              "
            >
              <template v-if="edit">{{
                getTranslate("updateOrder", $store.state.langActive)
              }}</template>
              <template v-if="!edit && !inOrder">{{
                getTranslate("addToOrder", $store.state.langActive)
              }}</template>
            </button>
          </template>

          <template v-else>
            <a
              href="#"
              class="prev"
              @click.prevent="
                $store.commit('gate/toggleIsView');
                $router.push('/order/' + editID);
              "
              >Назад</a
            ></template
          >
          <button
            class="btn-clear"
            @click="
              $store.commit('gate/toggleIsView');
              $store.commit('gate/clearForm');
            "
          >
            {{ getTranslate("clearForm", $store.state.langActive) }}
          </button>
        </div>

        <div class="plank"></div>
      </div>
    </div>

    <a href="#order2" v-if="visible" class="sticky-anchor">
      <span
        >{{ getTranslate("positionPrice", $store.state.langActive) }}:
        {{ convert(positionPrice) }} {{ $store.getters.getCurrencyIcon }}</span
      >
      <div class="white-shevron"></div>
    </a>
  </div>
</template>

<script>
import RelatedProducts from "@/components/RelatedProducts";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "Gate",
  components: {
    RelatedProducts,
  },
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    const options = {
      rootMargin: "0px",
      threshold: 0.1,
    };
    const callback = (entries, observer) => {
      if (entries[0].isIntersecting) {
        this.visible = false;
      } else {
        if (window.innerWidth <= 768) {
          this.visible = true;
        } else {
          this.visible = false;
        }
      }
    };
    const observer = new IntersectionObserver(callback, options);
    observer.observe(this.$refs.orderGate);
  },
  computed: {
    ...mapState({
      cardsState: (state) => state.gate.cardsState,
      position: (state) => state.gate.position,
      price: (state) => state.gate.price,
      edit: (state) => state.gate.edit,
      view: (state) => state.gate.view,
      editID: (state) => state.gate.editID,
      inOrder: (state) => state.gate.inOrder,
    }),
    ...mapGetters("gate", [
      "positionPrice",
      "getAccessoriesLeft",
      "getAccessoriesRight",
      "colorImage",
      "getDesignOption",
      "getBoxOptions",
      "showAlert",
      "isOrderAlert",
      "getID",
    ]),
    ...mapGetters("locale", ["getTranslate"]),
    checkedAcc: {
      set(val) {
        this.updateChecked(val);
      },
      get() {
        return this.position.checkedControl;
      },
    },
    designChecked: {
      set(val) {
        this.updateCheckedDesign(val);
      },
      get() {
        return this.position.designChecked;
      },
    },
    boxChecked: {
      set(val) {
        this.updateCheckedBox(val);
      },
      get() {
        return this.position.box;
      },
    },
  },
  methods: {
    convert(price) {
      if (this.$store.state.currencyConvert) {
        return price.ua.sumWithBox.toFixed(2);
      } else {
        return price.eur.sumWithBox.toFixed(2);
      }
    },
    updateWidth(e) {
      this.$store.commit("gate/setPositionValue", {
        key: "width",
        value: e.target.value,
      });
    },
    updateHeight(e) {
      this.$store.commit("gate/setPositionValue", {
        key: "height",
        value: e.target.value,
      });
    },
    updateLintel(e) {
      this.$store.commit("gate/setPositionValue", {
        key: "lintel",
        value: e.target.value,
      });
    },
    updateType(e) {
      this.$store.commit("gate/setPositionValue", {
        key: "type",
        value: e.target.value,
      });
    },
    updateMounting(e) {
      this.$store.commit("gate/setPositionValue", {
        key: "mounting",
        value: e.target.value,
      });
    },
    updateColor(e) {
      this.$store.commit("gate/setPositionValue", {
        key: "color",
        value: e.target.value,
      });
    },
    updateDelivery(e) {
      this.$store.commit("gate/setPositionValue", {
        key: "delivery",
        value: e.target.value,
      });
    },
    updateControl(e) {
      this.$store.commit("gate/setPositionValue", {
        key: "control",
        value: e.target.value,
      });
    },
    updateTitle(e) {
      this.$store.commit("gate/setPositionValue", {
        key: "name",
        value: e.target.value,
      });
    },
    updateChecked(val) {
      this.$store.commit("gate/setCheckedElements", val);
    },
    updateCheckedDesign(val) {
      this.$store.commit("gate/setCheckedElementsDesign", val);
    },
    updateCheckedBox(val) {
      this.$store.commit("gate/setCheckedElementsBox", val);
    },
    setAccInput(e) {
      this.$store.commit("gate/setAccInput", {
        key: e.target.name,
        value: e.target.value,
      });
    },
    checkAccSumInput(key) {
      this.$store.commit("gate/setAccInput", {
        key: key,
        value: 1,
      });
    },
    activeChecked(key) {
      return this.position.checkedAccessories.indexOf(key) != -1;
    },
  },
};
</script>

<style scoped>
.prof-img {
  position: relative;
}

.prof-img .label {
  position: absolute;
  background: #ee3124;
  font-size: 11px;
  color: #fff;
  padding: 3px;
}
#order-info,
label,
.card-head,
.btn-clear {
  cursor: pointer;
}
.delta_message {
  color: #f13333;
  font-weight: 700;
  margin-bottom: 15px;
}
.alert_error {
  background: #f13333;
  color: white;
  margin-top: 20px;
  margin-right: 30px;
  padding: 15px;
}
.not_price {
  font-size: 20px !important;
}
.prev {
  font-size: 20px;
  color: #ee3124;
  text-align: center;
  margin: 0 auto;
  display: block;
}
.red {
  color: #ee3124;
}
</style>
