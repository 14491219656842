<template>
  <div class="popup" id="more-info">
    <div class="popup-wrap popup-wrap-info">
      <span class="h3 red">{{
                getTranslate("complection", $store.state.langActive).toUpperCase()
              }}</span>
      <span class="h3" v-if="position.type != ''">{{price.types[position.type].title[$store.state.langActive]}}</span>
      <div class="curr-block">
        <div class="currency-wrap" v-if="position.type != ''" v-html="price.price[position.type].complection[$store.state.langActive]">
          
        </div>
      </div>
      <img
        class="close_all more-i-cloz cursor-point"
        src="/icons/cloz.svg"
        @click="$store.commit('popups/toggleSwingComplection')"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  computed: {
    ...mapState({
      cardsState: (state) => state.swing_gate.cardsState,
      position: (state) => state.swing_gate.position,
      price: (state) => state.swing_gate.price,
    }),
    ...mapGetters("locale", ["getTranslate"]),
  },
};
</script>

<style scoped>
</style>