<template>
  <header-vue></header-vue>
  <main
    :class="{
      alert_show:
        $store.state.alerts.length > 0 && $store.state.alerts[0]['preview'],
    }"
  >
    <router-view></router-view>
  </main>
  <footer-vue v-if="isWindow"></footer-vue>
  <currency-popup
    v-show="$store.state.popups.isActiveCurrency"
  ></currency-popup>
  <settings-popup
    v-show="$store.state.popups.isActiveSettings"
  ></settings-popup>
  <product-details-popup
    v-show="$store.state.popups.isActiveProductDetails"
  ></product-details-popup>
  <complection-popup
    v-show="$store.state.popups.isActiveComplection"
  ></complection-popup>
  <sliding-complection-popup
    v-show="$store.state.popups.isActiveSlidingComplection"
  ></sliding-complection-popup>
  <swing-complection-popup
    v-show="$store.state.popups.isActiveSwingComplection"
  ></swing-complection-popup>
  <door-complection-popup
    v-show="$store.state.popups.isActiveDoorComplection"
  ></door-complection-popup>
  <!-- <alert-popup></alert-popup> -->
</template>

<script>
import HeaderVue from "@/components/HeaderVue";
import FooterVue from "@/components/FooterVue";
import CurrencyPopup from "@/components/popups/CurrencyPopup";
import SettingsPopup from "@/components/popups/SettingsPopup";
import ProductDetailsPopup from "@/components/popups/ProductDetailsPopup";
import ComplectionPopup from "@/components/popups/ComplectionPopup";
import SlidingComplectionPopup from "@/components/popups/SlidingComplectionPopup";
import SwingComplectionPopup from "@/components/popups/SwingComplectionPopup";
import DoorComplectionPopup from "@/components/popups/DoorComplectionPopup";
import AlertPopup from "@/components/popups/AlertPopup";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    HeaderVue,
    FooterVue,
    CurrencyPopup,
    SettingsPopup,
    ProductDetailsPopup,
    ComplectionPopup,
    SlidingComplectionPopup,
    SwingComplectionPopup,
    DoorComplectionPopup,
    AlertPopup,
  },
  computed: {
    ...mapState({
      isActiveSettings: (state) => state.popups.isActiveSettings,
      isActiveCurrency: (state) => state.popups.isActiveCurrency,
    }),
    isWindow: function () {
      let isInIFrame = window.location != window.parent.location ? false : true;
      return isInIFrame;
    },
  },
  methods: {
    ...mapActions("rollet", [
      "loadRolletPrice",
      "loadRolletPosition", // -> this.foo()
    ]),
    ...mapActions("insulated_rollet", [
      "loadInsulatedRolletPrice",
      "loadRolletPosition", // -> this.foo()
    ]),
    ...mapActions("gate", [
      "loadGatePrice", // -> this.foo()
    ]),
    ...mapActions("sliding_gate", [
      "loadSlidingGatePrice", // -> this.foo()
    ]),
    ...mapActions("swing_gate", [
      "loadSwingGatePrice", // -> this.foo()
    ]),
    ...mapActions("door", [
      "loadDoorPrice", // -> this.foo()
    ]),
    ...mapActions("currency", [
      "loadCurrency", // -> this.foo()
    ]),
    ...mapActions([
      "loadProducts", // проксирует `this.increment()` в `this.$store.dispatch('increment')`
      "loadOrder", // проксирует `this.increment()` в `this.$store.dispatch('increment')`
      "loadAlerts", // проксирует `this.increment()` в `this.$store.dispatch('increment')`
    ]),
  },

  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.$store.commit("deferredPrompt", e);
    });
    window.addEventListener("appinstalled", () => {
      this.$store.commit("deferredPrompt", null);
    });
    this.loadInsulatedRolletPrice();
    this.loadRolletPrice();
    this.loadRolletPosition();
    this.loadGatePrice();
    this.loadSlidingGatePrice();
    this.loadSwingGatePrice();
    this.loadDoorPrice();
    this.loadProducts();
    // this.loadAlerts();
    this.loadCurrency();
    this.loadOrder();
    let currencyConvert = localStorage.getItem("currencyConvert");
    if (currencyConvert != undefined) {
      if (currencyConvert === "true") {
        this.$store.commit("setCheckedConvert", true);
      }
      if (currencyConvert === "false") {
        this.$store.commit("setCheckedConvert", false);
      }
    }
    let hideCards = localStorage.getItem("hideCards");
    if (hideCards != undefined) {
      if (hideCards === "true") {
        this.$store.commit("setHideCards", true);
        this.$store.commit("rollet/setHideCards", true);
        this.$store.commit("gate/setHideCards", true);
      }
      if (hideCards === "false") {
        this.$store.commit("setHideCards", false);
        this.$store.commit("rollet/setHideCards", false);
        this.$store.commit("gate/setHideCards", false);
      }
    }
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background: #f13333;
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #f13333;
}
</style>
