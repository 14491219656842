import axios from 'axios'
import VueAxios from 'vue-axios'

export const SwingGate = {
    state: () => ({
        cardsState: {
            size: false,
            type: false,
            lift_type: false,
            design: false,
            accessories: false,
            box: false,
        },
        position: {
            width: 0,
            height: 0,
            type: '',
            color: '',
            designChecked: [],
            box: null,
            checkedAccessories: [],
            accInput: {},
            discount: 0,
            name: ''
        },
        edit: false,
        view: false,
        inOrder: false,
        editID: 0,
        price: {}

    }),
    mutations: {
        toggleCard(state, key) {
            state.cardsState[key] = !state.cardsState[key];
        },
        editPosition(state, data) {
            state.position = data.position;
            state.editID = data.id;
            state.edit = true;
            state.inOrder = false
        },
        viewPosition(state, data) {
            state.position = data.position;
            state.editID = data.id;
            state.view = true;
        },
        toggleIsView(state) {
            state.view = false;
        },
        toggleIsEdit(state, id) {
            state.edit = false;
            state.editID = 0;
        },
        toggleInOrder(state) {
            state.inOrder = true;
        },
        setHideCards(state, val) {
            state.cardsState = {
                size: val,
                type: val,
                lift_type: val,
                design: val,
                accessories: val,
                box: val,
            };
        },
        updatePrice(state, price) {
            state.price = price;
        },
        setDefaultValue(state, data) {
            let inputAcc = {};
            try {
                inputAcc = state.position.accInput;
                let inputAccKey = Object.keys(state.position.accInput);
                for (let i = 0; i < inputAccKey.length; i++) {
                    inputAcc[inputAccKey[i]] = 0;
                }
            } catch (error) {
                inputAcc = {};
            }
            let position = localStorage.getItem('swingGate');
            if (position != undefined) {
                state.position = JSON.parse(position);
            } else {
                state.position = {
                    width: 0,
                    height: 0,
                    type: Object.keys(state.price.types)[0],
                    color: Object.keys(state.price.colors)[0],
                    designChecked: [],
                    box: Object.keys(state.price.box)[0],
                    checkedAccessories: [],
                    accInput: {},
                    discount: 0,
                    name: ''
                }
            };
        },
        clearForm(state) {
            let inputAcc = {};
            try {
                inputAcc = state.position.accInput;
                let inputAccKey = Object.keys(state.position.accInput);
                for (let i = 0; i < inputAccKey.length; i++) {
                    inputAcc[inputAccKey[i]] = 0;
                }
            } catch (error) {
                inputAcc = {};
            }
            state.position = {
                width: 0,
                height: 0,
                lintel: 0,
                type: Object.keys(state.price.types)[0],
                color: Object.keys(state.price.colors)[0],
                designChecked: [],
                box: Object.keys(state.price.box)[0],
                checkedAccessories: [],
                accInput: {},
                discount: 0,
                name: ''
            };
            state.inOrder = false;
            state.view = false;
            state.edit = false;
            localStorage.removeItem('swingGate');
        },
        setPositionValue(state, data) {
            if (isNaN(data.value)) {
                state.position[data.key] = data.value;
                if (data.key == 'type') {
                    // console.log(data.key);
                    state.position.checkedAccessories = [];
                    state.position.accInput = {};
                }
            } else {
                state.position[data.key] = Number(data.value);
            }
            localStorage.setItem('swingGate', JSON.stringify(state.position));
        },
        setCheckedElements(state, value) {
            if (value.check) {
                state.position.checkedAccessories.push(value.key);
            } else {
                state.position.checkedAccessories.splice(state.position.checkedAccessories.indexOf(value.key), 1)
            }
            localStorage.setItem('swingGate', JSON.stringify(state.position));
        },
        setCheckedElementsDesign(state, value) {
            state.position.designChecked = value;
            localStorage.setItem('swingGate', JSON.stringify(state.position));
        },
        setCheckedElementsBox(state, value) {
            state.position.box = value;
            localStorage.setItem('swingGate', JSON.stringify(state.position));
        },
        setAccInput(state, data) {
            state.position.accInput[data.key] = Number(data.value);
            localStorage.setItem('swingGate', JSON.stringify(state.position));
        }
    },
    getters: {
        getID: state => {
            if (state.edit) {
                return state.editID;
            } else {
                return Date.now();
            }
        },
        colorImage: state => {
            try {
                return state.price.colors[state.position.color].image;
            } catch (error) {
                return '';
            }
        },
        boxImage: state => {
            try {
                return state.price.delivery[state.position.delivery].image;
            } catch (error) {
                return '';
            }
        },
        getDesignOption: state => {
            try {
                let position = state.position;
                let width = position.width;
                let height = position.height;
                let color = position.color;
                let designOption = state.price.colors[color].design;
                let square = width * height / 1000 / 1000;
                let options = {};
                if (designOption != false) {
                    let options_keys = Object.keys(designOption);
                    options_keys.forEach(element => {
                        let condition = designOption[element].condition;
                        if (condition != undefined) {
                            let max_w = condition.max_w != undefined ? condition.max_w : false;
                            let max_h = condition.max_h != undefined ? condition.max_h : false;
                            let min_w = condition.min_w != undefined ? condition.min_w : false;
                            let min_h = condition.min_h != undefined ? condition.min_h : false;
                            let max_square = condition.max_square != undefined ? condition.max_square : false;

                            if ((max_h && max_h < height) || (max_w && max_w < width) || (max_h && max_h < height) || (min_w && min_w > width) || (min_h && min_h > height) || (max_square && max_square < square)) {
                                options[element] = {
                                    ...designOption[element],
                                    disabled: true
                                }
                            } else {
                                options[element] = {
                                    ...designOption[element],
                                    disabled: false
                                }
                            }

                        } else {
                            options[element] = {
                                ...designOption[element],
                                disabled: false
                            }
                        }
                    });

                }
                return options;
            } catch (error) {
                return {};
            }
        },
        getAccessoriesLeft: state => {
            try {
                let position = state.position;
                let width = position.width;
                let height = position.height;
                let square = width * height / 1000 / 1000;
                let accessories = state.price.price[position.type].accessories;
                let accessories_modify = {};
                let acc_keys = Object.keys(accessories);
                for (let i = 0; i < acc_keys.length; i++) {
                    if (i % 2 == 1) continue;
                    let key = acc_keys[i];
                    let item = accessories[key];
                    if (item.condition != undefined) {
                        let max_w = item.condition.max_w != undefined ? item.condition.max_w : false;
                        let max_h = item.condition.max_h != undefined ? item.condition.max_h : false;
                        let min_w = item.condition.min_w != undefined ? item.condition.min_w : false;
                        let min_h = item.condition.min_h != undefined ? item.condition.min_h : false;
                        let max_square = item.condition.max_square != undefined ? item.condition.max_square : false;
                        let min_or_h = item.condition.min_or_h != undefined ? item.condition.min_or_h : false;
                        let min_or_w = item.condition.min_or_w != undefined ? item.condition.min_or_w : false;

                        if ((min_or_h && min_or_h > height && min_or_w && min_or_w > width) || (max_w && max_w < width) || (max_h && max_h < height) || (min_w && min_w > width) || (min_h && min_h > height) || (max_square && max_square < square)) {
                            accessories_modify[key] = {
                                "title": item.title,
                                "disabled": true,
                                "type": item.price_mode,
                                "price": item.price,
                                "is_primary": item.is_primary !== undefined ? item.is_primary : false,
                            }
                        } else {
                            accessories_modify[key] = {
                                "title": item.title,
                                "disabled": false,
                                "type": item.price_mode,
                                "price": item.price,
                                "is_primary": item.is_primary !== undefined ? item.is_primary : false,
                            }
                        }
                    } else {
                        accessories_modify[key] = {
                            "title": item.title,
                            "disabled": false,
                            "type": item.price_mode,
                            "price": item.price,
                            "is_primary": item.is_primary !== undefined ? item.is_primary : false,
                        }
                    }
                }
                return accessories_modify;
            } catch (error) {
                return {};
            }
        },
        getAccessoriesRight: state => {
            try {
                let position = state.position;
                let width = position.width;
                let height = position.height;
                let square = width * height / 1000 / 1000;
                let accessories = state.price.price[position.type].accessories;
                let accessories_modify = {};
                let acc_keys = Object.keys(accessories);
                for (let i = 0; i < acc_keys.length; i++) {
                    if (i % 2 == 0) continue;
                    let key = acc_keys[i];
                    let item = accessories[key];
                    if (item.condition != undefined) {
                        let max_w = item.condition.max_w != undefined ? item.condition.max_w : false;
                        let max_h = item.condition.max_h != undefined ? item.condition.max_h : false;
                        let min_w = item.condition.min_w != undefined ? item.condition.min_w : false;
                        let min_h = item.condition.min_h != undefined ? item.condition.min_h : false;
                        let max_square = item.condition.max_square != undefined ? item.condition.max_square : false;
                        let min_or_h = item.condition.min_or_h != undefined ? item.condition.min_or_h : false;
                        let min_or_w = item.condition.min_or_w != undefined ? item.condition.min_or_w : false;
                        if ((min_or_h && min_or_h > height && min_or_w && min_or_w > width) || (max_w && max_w < width) || (max_h && max_h < height) || (min_w && min_w > width) || (min_h && min_h > height) || (max_square && max_square < square)) {
                            accessories_modify[key] = {
                                "title": item.title,
                                "disabled": true,
                                "type": item.price_mode,
                                "price": item.price,
                                "is_primary": item.is_primary !== undefined ? item.is_primary : false,
                            }
                        } else {
                            accessories_modify[key] = {
                                "title": item.title,
                                "disabled": false,
                                "type": item.price_mode,
                                "price": item.price,
                                "is_primary": item.is_primary !== undefined ? item.is_primary : false,
                            }
                        }
                    } else {
                        accessories_modify[key] = {
                            "title": item.title,
                            "disabled": false,
                            "type": item.price_mode,
                            "price": item.price,
                            "is_primary": item.is_primary !== undefined ? item.is_primary : false,
                        }
                    }
                }
                return accessories_modify;
            } catch (error) {
                return {};
            }
        },
        getBoxOptions: state => {
            try {
                return state.price.box;
            } catch (error) {
                console.log(error)
                return {};
            }
        },
        showAlert: (state, getters, rootState) => {
            try {
                let width = state.position.width;
                let height = state.position.height;
                let min_width = state.price.price[state.position.type].width[0];
                let min_height = state.price.price[state.position.type].height[0];
                let max_width = state.price.price[state.position.type].width[state.price.price[state.position.type].width.length - 1];
                let max_height = state.price.price[state.position.type].height[state.price.price[state.position.type].height.length - 1];
                let price = state.price.price[state.position.type];
                let widthItems = price['width'];
                let heightItems = price['height'];
                let widthIndex = 0;
                let heightIndex = 0;
                let widthTable = 0;
                let heightTable = 0;
                for (let i = 0; i < price.width.length; i++) {
                    if (width <= price.width[i]) {
                        widthIndex = i;
                        widthTable = price.width[i];
                        break;
                    }
                }
                for (let i = 0; i < price.height.length; i++) {
                    if (height <= price.height[i]) {
                        heightIndex = i;
                        heightTable = price.height[i];
                        break;
                    }
                }
                let sum = 0;
                // цена по сетке
                sum = price['size'][heightIndex][widthIndex];
                if (width >= 1000 && height >= 1000) {
                    if (sum == 0) {
                        if (rootState.langActive == 'ru') {
                            return 'Данный размер недоступен для заказа.';
                        } else {
                            return 'Даний розмір не доступний для замовлення.';
                        }

                    }
                    if (width < min_width || height < min_height) {
                        if (width >= 1000 && height >= 1000) {
                            if (rootState.langActive == 'ru') {
                                return 'Указаны неверные размеры.<br>Минимальные размеры: ширина - ' + min_width + 'мм, высота - ' + min_height + 'мм.<br>Максимальные размеры: ширина - ' + max_width + 'мм, высота - ' + max_height + 'мм.'
                            } else {
                                return 'Вказано невірні розміри.<br>Мінімальні розміри: ширина - ' + min_width + 'мм, висота - ' + min_height + 'мм.<br>Максимальні розміри: ширина - ' + max_width + 'мм, висота - ' + max_height + 'мм.'
                            }

                        }

                    }
                    if (width > max_width || height > max_height) {
                        if (rootState.langActive == 'ru') {
                            return 'Указаны неверные размеры.<br>Минимальные размеры: ширина - ' + min_width + 'мм, высота - ' + min_height + 'мм.<br>Максимальные размеры: ширина - ' + max_width + 'мм, высота - ' + max_height + 'мм.'
                        } else {
                            return 'Вказано невірні розміри.<br>Мінімальні розміри: ширина - ' + min_width + 'мм, висота - ' + min_height + 'мм.<br>Максимальні розміри: ширина - ' + max_width + 'мм, висота - ' + max_height + 'мм.'
                        }
                    }
                    return false;
                }
                return false;

            } catch (error) {
                return false;
            }

        },
        isOrderAlert: (state, getters, rootState) => {
            try {
                let width = state.position.width;
                let height = state.position.height;
                let price = state.price.price[state.position.type];
                let widthIndex = 0;
                let heightIndex = 0;
                for (let i = 0; i < price.width.length; i++) {
                    if (width <= price.width[i]) {
                        widthIndex = i;
                        break;
                    }
                }
                for (let i = 0; i < price.height.length; i++) {
                    if (height <= price.height[i]) {
                        heightIndex = i;
                        break;
                    }
                }
                // цена по сетке
                return price['alerts'][heightIndex][widthIndex];

            } catch (error) {
                return false;
            }

        },
        positionPrice: (state, getters, rootState) => {
            let position = {}
            let price = {};
            let type = '';
            let width = 0;
            let height = 0;
            let widthItems = 0;
            let heightItems = 0;
            let doors = state.price.doors;
            let door = false;
            let price_all = {};
            let activeAccessories = [];
            let activeDesignOption = [];
            try {
                position = state.position;
                price = state.price.price[position.type];
                price_all = state.price;
                width = position.width;
                height = position.height;
                widthItems = price['width'];
                heightItems = price['height'];
                activeAccessories = {
                    ...getters.getAccessoriesLeft,
                    ...getters.getAccessoriesRight
                };
                activeDesignOption = getters.getDesignOption;
                type = position.type;
            } catch (error) {
                return {
                    eur: {
                        sum: 0,
                        sumWithBox: 0
                    },
                    ua: {
                        sum: 0,
                        sumWithBox: 0
                    }
                }
            }


            if (width >= price['width'][0] && height >= price['height'][0]) {
                if (height > heightItems[heightItems.length - 1] || width > widthItems[widthItems.length - 1]) {
                    let sum = 0;

                    return {
                        eur: {
                            sum: 0,
                            sumWithBox: 0
                        },
                        ua: {
                            sum: 0,
                            sumWithBox: 0
                        }
                    }
                }
                let widthIndex = 0;
                let heightIndex = 0;
                let widthTable = 0;
                let heightTable = 0;
                for (let i = 0; i < price.width.length; i++) {
                    if (width <= price.width[i]) {
                        widthIndex = i;
                        widthTable = price.width[i];
                        break;
                    }
                }
                for (let i = 0; i < price.height.length; i++) {
                    if (height <= price.height[i]) {
                        heightIndex = i;
                        heightTable = price.height[i];
                        break;
                    }
                }
                let sum = 0;
                // цена по сетке
                sum = price['size'][heightIndex][widthIndex];

                if (price.height[heightIndex] != height && position.type == 'springs') {
                    sum *= (price_all.custom_size_overprice_percent / 100 + 1);
                }

                // наценка за цвет
                let color_makup = price_all.colors[position.color].markup / 100 * sum;

                // цена за дизайн
                let designSum = 0;
                position.designChecked.forEach(key => {
                    try {
                        let item_design = activeDesignOption[key];
                        // console.log(item_design);
                        if (item_design.price_mod == 'sum' && !item_design.disabled) {
                            designSum += item_design.price;
                        }
                        if (item_design.price_mod == 'markup' && !item_design.disabled) {

                            designSum += item_design.price / 100 * sum;
                        }
                    } catch (error) {

                    }

                });
                // цена аксессуаров
                let accSum = 0;
                position.checkedAccessories.forEach(key => {
                    try {
                        let item_acc = activeAccessories[key];
                        if (item_acc.type == 'sum' && !item_acc.disabled) {
                            accSum += item_acc.price;
                        }
                        if (item_acc.type == 'markup' && !item_acc.disabled) {
                            accSum += item_acc.price / 100 * sum;
                        }
                        if (item_acc.type == 'sum_input' && !item_acc.disabled) {
                            if (position.accInput[key] == undefined) {
                                accSum += 0 * item_acc.price;
                            } else {
                                accSum += position.accInput[key] * item_acc.price;
                            }
                        }
                        if (item_acc.type == 'sum_width_input' && !item_acc.disabled) {
                            if (position.accInput[key] == undefined) {
                                accSum += 0 * item_acc.price;
                            } else {
                                accSum += position.accInput[key] * item_acc.price * position.width / 1000;
                            }
                        }
                    } catch (error) {

                    }

                });

                sum += color_makup + accSum + designSum;
                sum = sum * (100 - position.discount) / 100;
                let box = 0;
                let square = widthTable * heightTable / 1000 / 1000;
                let boxes = getters.getBoxOptions;

                try {
                    if (position.box !== '' && position.box !== null) {
                        box = state.price.box[position.box].price;
                    }
                } catch (error) {
                    
                }



                // if (door) {
                //     box = 0;
                //     if (position.box.indexOf('standart') != -1) {
                //         box = price['size'][heightIndex][widthIndex] * 0.01;
                //     }
                // }

                let sumWithBox = sum + box;
                return {
                    eur: {
                        sum: sum,
                        sumWithBox: sumWithBox
                    },
                    ua: {
                        sum: sum * rootState.currency.currency.eur,
                        sumWithBox: sumWithBox * rootState.currency.currency.eur
                    }
                }
            } else {
                let sum = 0;

                return {
                    eur: {
                        sum: 0,
                        sumWithBox: 0
                    },
                    ua: {
                        sum: 0,
                        sumWithBox: 0
                    }
                }
            }


        },
        relatedProducts: (state, getters, rootState) => {
            try {
                let result = [];
                let productsRelated = state.price.products;
                let filtered = [];
                let height = state.position.height / 1000;
                let square = state.position.height / 1000 * state.position.width / 1000;
                let products = rootState.products.products;
                productsRelated.forEach(item => {
                    if (height <= item.max_h && square <= item.max_square && !getters.showAlert) {
                        filtered.push(item.id);
                    }
                });

                filtered.forEach(element => {
                    result = result.concat(products.filter(product => product.id == element));
                });
                return result;
            } catch (error) {
                return [];
            }
        },
    },
    actions: {
        loadSwingGatePrice({
            commit
        }) {
            axios.get('https://calculator.gant.ua/api/swing-gate.php').then((response) => {
                commit('updatePrice', response.data);
                commit('setDefaultValue', response.data);
            });
        }
    },
    namespaced: true
};