<template>
    <footer>
        <a href="https://gant.ua" class="logo-wrap"><img class="logo" src="/img/logo.svg"></a>
        <span class="copyright">Copyright © 2018 ООО "ГАНТ-ЮА"
        </span>
        <div class="foot-icon-wrap">
        <div class="menu-bottom" v-if="deferredPrompt">
            <span class="app-install-header">{{
              getTranslate("downloadApp", $store.state.langActive)
            }}</span>
            <div class="icon-wrap">
              <span
                class="app-wrap"
                  @click="install">
                 <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 172 172"><g fill="none" fill-rule="none" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="none" stroke-miterlimit="none" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none" fill-rule="nonzero" stroke-linejoin="miter" stroke-miterlimit="10"></path><g fill="#f13333" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2"><path d="M77.04167,21.5c0,-6.923 -5.61867,-12.54167 -12.54167,-12.54167h-43c-6.923,0 -12.54167,5.61867 -12.54167,12.54167v43c0,6.923 5.61867,12.54167 12.54167,12.54167h43c6.923,0 12.54167,-5.61867 12.54167,-12.54167z"></path><path d="M77.04167,107.5c0,-6.923 -5.61867,-12.54167 -12.54167,-12.54167h-43c-6.923,0 -12.54167,5.61867 -12.54167,12.54167v43c0,6.923 5.61867,12.54167 12.54167,12.54167h43c6.923,0 12.54167,-5.61867 12.54167,-12.54167z"></path><path d="M163.04167,21.5c0,-6.923 -5.61867,-12.54167 -12.54167,-12.54167h-43c-6.923,0 -12.54167,5.61867 -12.54167,12.54167v43c0,6.923 5.61867,12.54167 12.54167,12.54167h43c6.923,0 12.54167,-5.61867 12.54167,-12.54167z"></path><path d="M123.625,100.33333v57.33333c0,2.967 2.408,5.375 5.375,5.375c2.967,0 5.375,-2.408 5.375,-5.375v-57.33333c0,-2.967 -2.408,-5.375 -5.375,-5.375c-2.967,0 -5.375,2.408 -5.375,5.375z"></path><path d="M100.33333,134.375h57.33333c2.967,0 5.375,-2.408 5.375,-5.375c0,-2.967 -2.408,-5.375 -5.375,-5.375h-57.33333c-2.967,0 -5.375,2.408 -5.375,5.375c0,2.967 2.408,5.375 5.375,5.375z"></path></g></g></svg>
                 {{getTranslate("appInstall", $store.state.langActive)}}
                 </span>

            </div>
          </div>
        </div>

    </footer>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  computed: {
    ...mapState({
      deferredPrompt: (state) => state.deferredPrompt,
    }),
    ...mapGetters("locale", ["getTranslate"]),
  },
  methods: {
    async install() {
      this.deferredPrompt.prompt();
    }
  }
};
</script>

<style scoped>
.app-wrap {
  display: flex !important;
  align-items: center;
  width: 100% !important;
  cursor: pointer;
}

.app-wrap svg {
  width: 25px;
  margin-right: 5px;
}
footer .foot-icon-wrap .app-wrap {
  margin: 5px 0 0 0;
}
.app-install-header {
  text-align: center;
  font-weight: 700;
}
</style>